import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Subscription } from 'rxjs';

import { AdminService } from "../admin.service";
import { MessagesService } from '../../../reusableComponents/messages/messages.service';

@Component({
  selector: 'app-modal-eliminar-usuario',
  templateUrl: './modal-eliminar-usuario.component.html',
  styleUrls: ['./modal-eliminar-usuario.component.css']
})
export class ModalEliminarUsuarioComponent implements OnInit {
  loadingService: boolean = false;
  messageSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<ModalEliminarUsuarioComponent>,
    private messageService: MessagesService,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: {
      userId: string,
      TipodeDocumento: string,
      usuario: string
    }
  ) { }

  ngOnInit(): void { }

  confirmar() {
    this.loadingService = true;
    this.dialogRef.disableClose = true;
    this.adminService.deleteUser(this.data.userId).subscribe(
      () => {
        this.dialogRef.close({ update: true })
        this.messageService.visibleMessage({
          imgName: "success.svg",
          texto1: "Usuario eliminado exitosamente.",
          button: "Cerrar"
        }, "Info");
      },
      () => {
        this.adminService.updateToken().subscribe(
          () => {
            this.adminService.deleteUser(this.data.userId).subscribe(
              () => {
                this.dialogRef.close({ update: true })
                this.messageService.visibleMessage({
                  imgName: "success.svg",
                  texto1: "Usuario eliminado exitosamente.",
                  button: "Cerrar"
                }, "Info");
              },
              error => {
                this.dialogRef.close({ update: false })
                this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada, por favor intente nuevamente." });
              }
            )
          },
          error => {
            this.dialogRef.close({ update: false })
            this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada, por favor intente nuevamente." });
          }
        )
      }
    )
  }

  cancelar() {
    this.dialogRef.close({ update: false })
  }
}
