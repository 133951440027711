<div mat-dialog-title>
    <h6>Detalle de crédito</h6>
    <button mat-dialog-close>X</button>
</div>
<h6 class="messageObligations" *ngIf="data.estadoCartera.estado === 'Vencido'">
    Recuerda que es importante mantener tus obligaciones al día
</h6>
<h6 class="messageObligations" *ngIf="data.estadoCartera.estado === 'Castigado'">
    Recuerda que es importante mantener tus obligaciones al día, después de realizar el abono a tu saldo en mora,
    recuerda comunicarte al 018000 417 019 o escríbenos al WhatsApp
    <a href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar" target="_blank">
        320 606 4004</a>
    para poder hacer efectivo el proceso
</h6>
<div mat-dialog-content>
    <div class="contentPart1">
        <div class="left">
            <h6>Referencia de pago</h6>
            <h5>{{data.estadoCartera.referenciaPago}}</h5>
            <h6>Fecha próximo pago</h6>
            <h5>{{data.estadoCartera.fechaProximoPago}}</h5>
            <h6>Estado</h6>
            <h5>{{ data.estadoCartera.estado === "Activo" ? "Al Día" : data.estadoCartera.estado ===
                ("Vencido" || "Castigado") ? "En Mora" : data.estadoCartera.estado }}</h5>
            <h6>Días en mora</h6>
            <h5>{{ data.estadoCartera.diasEnMora < 0 ? 0 : data.estadoCartera.diasEnMora }}</h5>
        </div>
        <div class="verticalLine"></div>
        <div class="right">
            <h6>Saldo</h6>
            <h5>{{ data.estadoCartera.saldoCancelacionTotal | currency:'USD':'symbol':'1.0' }}</h5>
            <h6>Valor próxima cuota</h6>
            <h5>{{ data.estadoCartera.montoProximoPago | currency:'USD':'symbol':'1.0'}}</h5>
            <h6>Saldo en mora</h6>
            <h5>{{ data.estadoCartera.saldoEnMora < 0 ? (0 | currency:'USD':'symbol':'1.0') :
                    (data.estadoCartera.saldoEnMora | currency:'USD':'symbol':'1.0') }}</h5>
        </div>
    </div>
    <div class="contentPart2">
        <h6>Monto del crédito</h6>
        <h5>{{ data.estadoCartera.montoDesembolsado | currency:'USD':'symbol':'1.0' }}</h5>
        <!--<h6>plazo</h6>
        <h5>{{data.estadoCartera.plazo}}</h5>-->
        <h6>Cuotas restantes</h6>
        <h5>{{data.estadoCartera.cuotasRestantes}}</h5>
    </div>
</div>
<mat-expansion-panel class="tasasTarifas">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Tasas y tarifas
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="tarifas">
        <h6>
            <span>Seguro de Vida deudores:</span> Monto*Plazo*Factor. (Cobro total se hace al momento del desembolso) El
            factor está sujeto a la renovación anual de la póliza que la corporación Interactuar realice.
        </h6>
        <!-- <h6>
            <span>Seguro Oncológico:</span> Por cada millón: $1.590*12 meses (cobro total se hace en el momento de
            desembolso)
        </h6>
        <h6>
            <span>Seguro progresa:</span> Montos de 0 a $2mm: $2.197 cobro mensual
        </h6>
        <h6>
            <span>Seguro progresa:</span> Montos mayor a $2mm y menor a $5mm: $5.491 cobro mensual
        </h6>
        <h6>
            <span>Seguro progresa:</span> Montos mayor a $5mm: $10.983 cobro mensual
        </h6> -->
    </div>
</mat-expansion-panel>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="closeButton">Cerrar</button>
</mat-dialog-actions>
