<app-actualizar-datos *ngIf="paso===1" (editPaso)="updatePaso($event)"
    (editFormActualizarDatos)="updateFormActualizarDatos($event)" [creditoPreaprobado]="creditoPreaprobado">
</app-actualizar-datos>
<!-- <app-datos-para-desembolso *ngIf="paso===2" (editPaso)="updatePaso($event)" [creditoPreaprobado]="creditoPreaprobado"
    (editFormDesembolso)="updateFormDesembolso($event)">
</app-datos-para-desembolso>
<app-datos-del-seguro *ngIf="paso===3" (editPaso)="updatePaso($event)" (editFormSeguro)="updateFormSeguro($event)"
    [creditoPreaprobado]="creditoPreaprobado">
</app-datos-del-seguro>
<app-resumen-credito *ngIf="paso===4" (editPaso)="updatePaso($event)" [creditoPreaprobado]="creditoPreaprobado"
    [dataFormDesembolso]="dataFormDesembolso" [dataFormActualizarDatos]="dataFormActualizarDatos"
    [dataFormSeguro]="dataFormSeguro">
</app-resumen-credito> -->