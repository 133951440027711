<div class="contenedor">
    <div class="header">
        <h1>Deuda Paz Y Salvo</h1>
        <hr />
        <mat-icon class="back-arrow" (click)="regresar()">arrow_back_ios_new</mat-icon>
    </div>

    <div class="contenido">
      <p class="creditoCancelado">
        Si cancelaste un crédito recientemente, tu crédito puede aparecer vigente entre 24 y 120 horas.
      <p>
        <table mat-table [dataSource]="dataSourcePaz" class="mat-elevation-z8">

            <ng-container matColumnDef="credito">
                <th mat-header-cell *matHeaderCellDef>Crédito</th>
                <td mat-cell *matCellDef="let element">
                    {{element.credito}}
                </td>
            </ng-container>


            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let element">
                    {{element.estado}}
                </td>
            </ng-container>

            <ng-container style="text-align: centerS" matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef>Seleccione una opción</th>
                <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                    <mat-checkbox (change)="onCreditToggled(element)" [checked]="seleccion.isSelected(element)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPaz"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPaz;"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
        <div class="pagos">
            <button *ngIf="descargar" (click)="openModalPazysalvo()">
                Descargar
            </button>
        </div>
    </div>


</div>
