import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import * as CryptoJS from "crypto-js";

import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { environment } from "../../../../environments/environment";
import { saveFormActualizarDatos } from '../state/solicitar-credito-sin-preabrobado.actions';
import { StateCreditos } from 'src/app/constants/interfaces/solicitar-credito.interface';

@Component({
  selector: 'app-actualizar-datos',
  templateUrl: './actualizar-datos.component.html',
  styleUrls: ['./actualizar-datos.component.css']
})

export class ActualizarDatosComponent implements OnInit {
  estadoCivil: [];
  tipoVivienda: [];
  /* actividadEconomica: []; */
  errorSubmit: boolean = false;
  messageBack: boolean = false;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  @Input() creditoPreaprobado: { codigoProducto: number, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, simulador: number | null, idMiembroCampania: string | null, actividadEconomica: string, famiempresa: string };
  @Output() editPaso = new EventEmitter<number>();
  @Output() editFormActualizarDatos = new EventEmitter<{ estadoCivil: string, tipoVivienda: string /*, actividadEconomica: string */ }>();
  formActualizarDatos: FormGroup;

  constructor(private messageService: MessagesService, private router: Router, private store: Store<StateCreditos>) {
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
    this.formActualizarDatos = new FormGroup({
      estadoCivil: new FormControl(null, [Validators.required]),
      tipoVivienda: new FormControl(null, [Validators.required]),
      telefonoArrendador: new FormControl(null),
      nombreArrendador: new FormControl(null)
      /* actividadEconomica: new FormControl(null, [Validators.required]) */
    });
    const valores = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.estadoCivil = valores[5].valores;
    this.tipoVivienda = valores[6].valores;
    /* this.actividadEconomica = valores[2].valores; */
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.formActualizarDatos.valid) {
      this.errorSubmit = false;
      this.store.dispatch(saveFormActualizarDatos({form: this.formActualizarDatos.value}))
      this.editPaso.emit(2);
    }
    else {
      this.errorSubmit = true;
    }
  }

  //Función que se ejecuta cuando se selecciona un parentesco, y que vuelve obligarorio el campo cual parentesco en caso de seleccionar otro
  onChange() {
    if (this.datosPersonales.get('tipoVivienda').value === 'A') {
      this.datosPersonales.get('telefonoArrendador').setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(7), Validators.maxLength(10)]);
      this.datosPersonales.get('telefonoArrendador').updateValueAndValidity();
      this.datosPersonales.get('nombreArrendador').setValidators([Validators.required, Validators.pattern(/^[A-zÀ-ú\s]*$/), Validators.maxLength(100)]);
      this.datosPersonales.get('nombreArrendador').updateValueAndValidity();
    }
    else {
      this.datosPersonales.get('telefonoArrendador').setValidators(null);
      this.datosPersonales.get('telefonoArrendador').updateValueAndValidity();
      this.datosPersonales.get('nombreArrendador').setValidators(null);
      this.datosPersonales.get('nombreArrendador').updateValueAndValidity();
      this.datosPersonales.controls['nombreArrendador'].setValue("");
      this.datosPersonales.controls['telefonoArrendador'].setValue("");
    }
  }

  regresar() {
    this.messageBack = true;
  }

  continuarSolicitud() {
    this.messageBack = false;
  }

  confirmarRegresar() {
    this.router.navigate(["/"]);
  }

  get datosPersonales() { return this.formActualizarDatos }
}
