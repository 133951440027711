<div mat-dialog-title>
    <h6>Actualización de datos</h6>
    <button mat-dialog-close>X</button>
</div>
<div mat-dialog-contentc class="modalActualizar">
    <form *ngIf="!loadingService" [formGroup]="formActualizarDatos" (ngSubmit)="onSubmit()">
        <div class="field">
            <label>Correo electrónico<span style="color: red; font-weight: 500;">*</span></label>
            <input formControlName="correoElectronico" placeholder="Ingresa tu correo electrónico"
                [ngClass]="{'input-error': ActualizarDatosForm.get('correoElectronico').invalid}">
            <h5
                *ngIf="ActualizarDatosForm.get('correoElectronico').invalid && (ActualizarDatosForm.get('correoElectronico').touched || ActualizarDatosForm.get('correoElectronico').dirty )">
                *Por favor valida el correo electrónico.
            </h5>
        </div>
        <div class="field">
            <label>Número celular<span style="color: red; font-weight: 500;">*</span></label>
            <input formControlName="numeroCelular" placeholder="Ingresa tu número celular"
                [ngClass]="{'input-error': ActualizarDatosForm.get('numeroCelular').invalid}">
            <h5
                *ngIf="ActualizarDatosForm.get('numeroCelular').invalid && (ActualizarDatosForm.get('numeroCelular').touched || ActualizarDatosForm.get('numeroCelular').dirty )">
                *Por favor valida el número celular; debe ser de 10 caracteres y contener sólo valores numéricos.
            </h5>
        </div>
        <div class="buttonsModal">
            <button mat-button mat-dialog-close style="background-color: #1C98C9;">Cancelar</button>
            <button type="submit" style="background-color: #DD512C"
                [disabled]="(ActualizarDatosForm.get('numeroCelular').value === data.numeroCelular && ActualizarDatosForm.get('correoElectronico').value === data.correoElectronico) || ActualizarDatosForm.get('numeroCelular').invalid || ActualizarDatosForm.get('correoElectronico').invalid">
                Actualizar</button>
        </div>
    </form>
    <div *ngIf="loadingService" class="loadingContainer">
        <h2>Actualizando</h2>
        <div class="uploader-status">
            <mat-spinner [diameter]="70"></mat-spinner>
        </div>
    </div>
</div>
