<div class="contenedor">
    <div class="header">
        <h1>Solicitar crédito</h1>
        <hr />
        <mat-icon class="back-arrow" (click)="regresar()">arrow_back_ios_new</mat-icon>
    </div>
    <div class="contenido">
        <div class="pasos">
            <div class="paso2">
                <div class="circle">
                    <div>1</div>
                </div>
                <h6 style="margin-top: 1vh">Actualizar</h6>
                <h6 style="text-align: center;">datos</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>2</div>
                </div>
                <h6 style="margin-top: 1vh; padding-left: 0.3em;">Datos para</h6>
                <h6>desembolso</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>3</div>
                </div>
                <h6 style="margin-top: 1vh">Datos del</h6>
                <h6 style="text-align: center;">seguro</h6>
            </div>
        </div>
        <div class="intro">
            <h4>Resumen</h4>
            <h6>Por favor verifica que los datos de tu negocio son los correctos, de lo contrario por favor comunícate
                con la línea 018000417019 o al Whatsapp <a
                    href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
                    target="_blank">
                    320 606 4004</a>, para realizar dicha actualización. </h6>
        </div>
        <div class="dataSimulador">
            <h4>Crédito solicitado</h4>
            <div class="simulacion">
                <div class="grid">
                    <div class="monto">
                        <h5>Monto</h5>
                        <h3>{{ creditoPreaprobado.monto | currency:'USD':'symbol':'1.0' }}</h3>
                    </div>
                    <div class="cuotaMensual">
                        <div style="display: flex; flex-direction: column; margin-right: 2vw;">
                            <h5>Cuota</h5>
                            <h5>mensual</h5>
                        </div>
                        <div class="valor" style="margin-right: 1vw">
                            <div>
                                <h4>{{ creditoPreaprobado.simulador | currency:'USD':'symbol':'1.0'}}</h4>
                                <h6>aproximadamente<span style="color: #e31e3a">*</span></h6>
                            </div>
                        </div>
                    </div>
                    <div class="cuotas">
                        <div style="display: flex; flex-direction: column; margin-right: 2vw;">
                            <h5>Número</h5>
                            <h5>de cuotas</h5>
                        </div>
                        <div class="meses">
                            <h4>{{ creditoPreaprobado.plazo }}</h4>
                            <h6>meses</h6>
                        </div>
                    </div>
                    <!-- <div class="cargos-cuotaAprox">
                        <h5>Cargos fijos</h5>
                        <h4>{{ creditoPreaprobado.Cargos | currency:'USD':'symbol':'1.0' }}</h4>
                    </div>
                    <div class="cargos-cuotaAprox">
                        <h5>Total cuota aproximada</h5>
                        <h4>{{ creditoPreaprobado.Total | currency:'USD':'symbol':'1.0' }}</h4>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="data">
            <div class="infoFamiempresa">
                <div>
                    <h6>Nombre de la famiempresa</h6>
                    <h6>{{creditoPreaprobado.famiempresa}}</h6>
                </div>
                <div>
                    <h6>Actividad económica</h6>
                    <h6>{{creditoPreaprobado.actividadEconomica}}</h6>
                </div>
            </div>
            <div class="infoPersonal">
                <h5>Información personal</h5>
                <div>
                    <h6>Estado civil</h6>
                    <h6>{{dataFormActualizarDatos.estadoCivil}}</h6>
                </div>
                <div>
                    <h6>Tipo de vivienda</h6>
                    <h6>{{dataFormActualizarDatos.tipoVivienda}}</h6>
                </div>
                <div *ngIf="dataFormActualizarDatos.nombreArrendador !== ''">
                    <h6>Nombre del arrendador</h6>
                    <h6>{{dataFormActualizarDatos.nombreArrendador}}</h6>
                </div>
                <div *ngIf="dataFormActualizarDatos.telefonoArrendador !== ''">
                    <h6>Teléfono del arrendador</h6>
                    <h6>{{dataFormActualizarDatos.telefonoArrendador}}</h6>
                </div>
            </div>
            <div class="dataDesembolso">
                <h5>Datos para el desembolso</h5>
                <div>
                    <h6>Entidad bancaria</h6>
                    <h6>{{dataFormDesembolso.banco}}</h6>
                </div>
                <div>
                    <h6>Tipo de cuenta</h6>
                    <h6>{{dataFormDesembolso.tipoCuenta}}</h6>
                </div>
                <div>
                    <h6>Número de cuenta bancaria</h6>
                    <h6>{{dataFormDesembolso.numeroCuenta}}</h6>
                </div>
            </div>
            <div class="beneficiariosSeguro">
                <h5>Beneficiarios del seguro</h5>
                <div *ngFor="let beneficiario of dataFormSeguro">
                    <div class="beneficiario">
                        <div>
                            <h6>Nombre de beneficiario</h6>
                            <h6>{{beneficiario.nombreCompleto}}</h6>
                        </div>
                        <div>
                            <h6>Identificación</h6>
                            <h6>{{ beneficiario.tipoDocumento === "Cedula Ciudadania" ? "C.C.": "C.E." }}
                                {{beneficiario.numeroDocumento}}</h6>
                        </div>
                        <div>
                            <h6>Parentesco</h6>
                            <h6>{{beneficiario.parentesco}}</h6>
                        </div>
                        <div *ngIf='beneficiario.cualParentesco !== ""'>
                            <h6>Cuál parentesco</h6>
                            <h6>{{beneficiario.cualParentesco}}</h6>
                        </div>
                        <div>
                            <h6>Porcentaje asignado</h6>
                            <h6>{{beneficiario.porcentaje}}%</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button (click)="actualizarPerfil()"
            style="background-color: transparent; border: none; text-decoration: underline; color: var(--secondary); margin-top: 0.5vh">
            Actualizar datos personales
        </button> -->
        <div class="buttons">
            <button class="cancelar" (click)="regresar()">Regresar</button>
            <button class="continuar" (click)="solicitarCredito()">Solicitar</button>
        </div>
    </div>
</div>

<div class="message" *ngIf="messageBack">
    <div class="card">
        <button class="x" (click)="continuarSolicitud()">X</button>
        <img src="../../../../assets/Images/warning.svg">
        <h6>Estás a punto de retornar al paso anterior, recuerda que tendrás que volver a llenar los datos
            del seguro.</h6>
        <h6>¿Estás seguro de que deseas abandonarlo?</h6>
        <div class="buttons">
            <button class="continuar" (click)="continuarSolicitud()">Continuar solicitud</button>
            <button class="cancelar" (click)="confirmarRegresar()">Si, regresar</button>
        </div>
    </div>
</div>
