import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import * as CryptoJS from "crypto-js"

import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { ModalActualizarPerfilComponent } from "../../home/modal-actualizar-perfil/modal-actualizar-perfil.component";
import { environment } from "../../../../environments/environment";
import { StateCreditos } from "../../../constants/interfaces/solicitar-credito.interface";
import { DatosSeguroService } from "./datos-seguro.service";

@Component({
  selector: 'app-datos-seguro',
  templateUrl: './datos-seguro.component.html',
  styleUrls: ['./datos-seguro.component.css']
})
export class DatosSeguroComponent implements OnInit {
  messageBack: boolean = false;
  changePercentage: boolean = false;
  initialPercentage: string[] = [];
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  creditoPreaprobado$: Observable<any> = this.store.select(state => state.solicitarCredito.preaprobado);
  dataForm1$: Observable<any> = this.store.select(state => state.solicitarCredito.solicitarCreditoStep1);
  creditoPreaprobado: { codigoProducto: number, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, idMiembroCampania: string | null };
  dataForm1: { banco: string, tipoCuenta: string, numeroCuenta: string } | null;
  tipoDocumento: [];
  parentesco: [];
  formSolicitarCreditos2: FormArray = new FormArray([
    new FormGroup({
      nombreCompleto: new FormControl("", [Validators.required, Validators.pattern(/^[A-zÀ-ú\s]*$/)]), //Sin tildes ni ñ a-zA-Z\s
      tipoDocumento: new FormControl(null, [Validators.required]),
      numeroDocumento: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
      parentesco: new FormControl(null, [Validators.required]),
      cualParentesco: new FormControl(""),
      porcentaje: new FormControl("", [Validators.required, Validators.pattern('^[0-9.]*$')])
    })
  ]);
  errorSubmit: boolean = false;

  constructor(private messageService: MessagesService, private router: Router, private authService: MsalService, private datosSeguroService: DatosSeguroService, private loadingService: LoadingInfoService, private Modal: MatDialog, private store: Store<StateCreditos>) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
    const valores = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8))
    this.tipoDocumento = valores[1].valores;
    this.parentesco = valores[3].valores;
    this.creditoPreaprobado$.pipe(take(1)).subscribe(data => this.creditoPreaprobado = data);
    this.dataForm1$.pipe(take(1)).subscribe(data => this.dataForm1 = data);
    if (Object.keys(this.dataForm1).length === 0) this.router.navigate(["/"]);
  }

  ngOnInit(): void {
  }

  addBeneficiario() {
    if (this.formSolicitarCreditos2.value.length < 3) {
      const group = new FormGroup({
        nombreCompleto: new FormControl("", [Validators.required]),
        tipoDocumento: new FormControl(null, [Validators.required]),
        numeroDocumento: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
        parentesco: new FormControl(null, [Validators.required]),
        cualParentesco: new FormControl(""),
        porcentaje: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')])
      });
      this.formSolicitarCreditos2.push(group);
    }
    else {
      this.messageService.visibleMessage({
        imgName: "error.svg",
        texto1: "Sólo se permiten máximo 3 benefeciarios.",
        button: "Aceptar",
      }, "Error");
    }
  }

  deleteBeneficiario(index: number) {
    this.formSolicitarCreditos2.removeAt(index)
  }

  onSubmit() {
    this.changePercentage = false;
    if (this.formSolicitarCreditos2.valid) {
      this.errorSubmit = false;
      //VALIDAR PORCENTAJES
      let sumaPorcentaje: number = 0;
      for (const form of this.formSolicitarCreditos2.value) {
        this.initialPercentage.push(form.porcentaje)
        sumaPorcentaje += parseInt(form.porcentaje);
      }
      if (sumaPorcentaje === 100) {
        this.loadingService.startLoading();
        //Ciclo para evitar que se envíe un valor de cualParentesco, sabiendo que no se seleccionó otro como valor de parentesco
        for (let index = 0; index < this.formSolicitarCreditos2.value.length; index++) {
          if (this.formSolicitarCreditos2.value[index].parentesco !== "Otro") {
            this.formSolicitarCreditos2.value[index].cualParentesco = "";
          }
        }
        const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
        let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
        this.generarRespuesta(this.dataForm1, this.formSolicitarCreditos2.value, this.creditoPreaprobado, extension_TipodeDocumento, CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), user.Correo, user.NumeroCelular);
      }
      else {
        this.changePercentage = true;
      }
    }
    else {
      this.errorSubmit = true;
    }
  }

  generarRespuesta(
    dataForm1: { banco: string, tipoCuenta: string, numeroCuenta: string },
    formSolicitarCreditos2: { nombreCompleto: string, tipoDocumento: string, numeroDocumento: string, parentesco: string, porcentaje: number },
    creditoPreaprobado: { codigoProducto: number, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, idMiembroCampania: string | null },
    extension_TipodeDocumento: string,
    extension_Numerodedocumento: string,
    correoElectronico: string,
    numeroCelular: string
  ): void {
    const respuesta = {
      "tipoDocumento": extension_TipodeDocumento,
      "numeroDocumento": extension_Numerodedocumento,
      "monto": creditoPreaprobado.monto,
      "plazo": creditoPreaprobado.plazo,
      "idMiembroCampania": creditoPreaprobado.idMiembroCampania,
      "correoElectronico": correoElectronico,
      "producto": creditoPreaprobado.codigoProducto,
      "aceptoCliente": true,
      "tipoRespuesta": "Condiciones Iguales",
      "medioRespuesta": "Respuesta Web",
      "fechaRespuesta": moment().format('YYYY-MM-DD HH:mm:ss'),
      "tipoCuenta": dataForm1.tipoCuenta,
      "numeroCuenta": dataForm1.numeroCuenta,
      "banco": dataForm1.banco,
      "beneficiarios": formSolicitarCreditos2,
      "numeroCelular": numeroCelular
    }
    this.datosSeguroService.sendResponseCredito(localStorage.getItem("tokenSalesforce"), localStorage.getItem("tokenB2C"), respuesta).subscribe(
      data => {
        if (data.encabezado.mensaje.estado === "OK") {
          this.messageService.visibleMessage({
            imgName: "success.svg",
            texto1: "Tú solicitud de crédito está en proceso.",
            texto2: "Pronto nos comunicaremos contigo para continuar con el desembolso.",
            button: "Finalizar",
          }, "Info");
          this.router.navigate(["/"], { queryParams: { success: 'solicitar-credito' } });
        }
        else {
          this.loadingService.stopLoading()
          this.messageService.errorMessage({ title: `Error envío de respuesta cliente`, texto1: `${data.encabezado.mensaje.estado}. ${data.encabezado.mensaje.descripcion}` })
          this.router.navigate(["/solicitar-credito-2"], { queryParams: { error: 'solicitar-credito' } });
        }
      },
      error => {
        this.loadingService.stopLoading()
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
      }
    )
  }

  regresar() {
    this.messageBack = true;
  }

  continuarSolicitud() {
    this.messageBack = false;
  }

  confirmarRegresar() {
    this.router.navigate(["/solicitar-credito-1"]);
  }

  showInfo() {
    this.messageService.visibleMessage({
      imgName: "info.svg",
      texto1: "La suma de los porcentajes asignados a los beneficiarios deben sumar el 100%.",
      button: "Aceptar",
    }, "Info");
  }

  //Función que se ejecuta cuando se selecciona un parentesco, y que vuelve obligarorio el campo cual parentesco en caso de seleccionar otro
  onChange(index: number) {
    if (this.formSolicitarCreditos2.value[index].parentesco === "Otro") {
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').setValidators([Validators.required])
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').updateValueAndValidity();
    }
    else {
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').setValidators(null)
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').updateValueAndValidity();
      this.formSolicitarCreditos2.value[index].cualParentesco = "";
    }
  }

  totalPercentage() {
    let total: number = 0;
    for (let Beneficiario of this.formSolicitarCreditos2.value) {
      if (Beneficiario.porcentaje === "") {
        total += 0;
      }
      else {
        total += parseInt(Beneficiario.porcentaje);
      }
    }
    return total;
  }

  closeChangePercentage() {
    //Retornando a los valores iniciales para que no se quede con los valores modificados en la modal
    for (let index = 0; index < this.initialPercentage.length; index++) {
      this.formSolicitarCreditos2.value[index].porcentaje = this.initialPercentage[index]
    }
    this.initialPercentage = [];
    this.changePercentage = false;
  }

  actualizarPerfil() {
    let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.Modal.open(ModalActualizarPerfilComponent, {
      data: {
        correoElectronico: user.Correo,
        numeroCelular: user.NumeroCelular,
        type: 0
      },
      maxWidth: '100vw',
      maxHeight: '95vh'
    });
  }

  get beneficiarios(): FormArray {
    return this.formSolicitarCreditos2 as FormArray;
  }
}
