<div class="contenedor">
  <div class="header">
    <h1>Año Gravable</h1>
    <hr />
    <mat-icon class="back-arrow" (click)="regresar()"
      >arrow_back_ios_new</mat-icon
    >
  </div>

  <div class="contenido">
    <p class="creditoCancelado">
      Por este medio sólo podrá generar el certificado del Año Gravable
      correspondiente al año anterior. Si necesitas información de un año
      diferente, no dudes en comunicarte a nuestra línea gratuita nacional
      <strong style="color: #007bff">018000417019</strong>, escribirnos por
      WhatsApp al número
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >, o visitar la oficina más cercana.
    </p>
    <table mat-table [dataSource]="dataSourceAnio" class="mat-elevation-z8">
      <ng-container matColumnDef="credito">
        <th mat-header-cell *matHeaderCellDef>Crédito</th>
        <td mat-cell *matCellDef="let element">
          {{ element.credito }}
        </td>
      </ng-container>

      <ng-container matColumnDef="calidad">
        <th mat-header-cell *matHeaderCellDef>Calidad</th>
        <td mat-cell *matCellDef="let element">
          {{ element.calidad }}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.estado }}
        </td>
      </ng-container>

      <ng-container style="text-align: centerS" matColumnDef="option">
        <th mat-header-cell *matHeaderCellDef>Seleccione una opción</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="$event.stopPropagation()"
        >
          <mat-checkbox
            (change)="onCreditToggled(element)"
            [checked]="seleccion.isSelected(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsPaz"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPaz"></tr>
    </table>
    <div class="pagos">
      <button *ngIf="descargar" (click)="descargarAnoGrabable()">
        Descargar
      </button>
    </div>
  </div>
</div>
