<div mat-dialog-title>
</div>
<div mat-dialog-content>
    <picture>
        <source media="(min-width: 900px)" srcset="../../../../assets/Images/puntosDePago1280x720.jpg">
        <source media="(min-width: 480px)" srcset="../../../../assets/Images/puntosDePago21x32.jpg">
        <img src="../../../../assets/Images/puntosDePago21x32.jpg" alt="pago-manual" style="width: 100%;">
    </picture>
</div>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="closeImage">Cerrar</button>
</mat-dialog-actions>