import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

@Component({
  selector: 'app-modal-pago-manual',
  templateUrl: './modal-pago-manual.component.html',
  styleUrls: ['./modal-pago-manual.component.css']
})
export class ModalPagoManualComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalPagoManualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) { }

  ngOnInit(): void {
  }

  openImage() {
    this.dialogRef.close({ openImage: true })
  }
}
