import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculoCuotaMensual'
})
export class CalculoCuotaMensualPipe implements PipeTransform {

  transform(monto: number, cuotas: number): number {
    return Math.ceil(monto / cuotas);
  }

}
