<div class="contenedor">
    <div class="header">
        <h1>Solicitar crédito</h1>
        <hr />
        <mat-icon class="back-arrow" (click)="regresar()">arrow_back_ios_new</mat-icon>
    </div>
    <div class="contenido">
        <div class="info-empresa">
            <h6 style="margin-bottom: 2vh">Estás solicitando el crédito para:</h6>
            <div class="nombre">
                <h6>Famiempresa</h6>
                <h6>{{creditoPreaprobado.famiempresa}}</h6>
            </div>
            <div class="nombre">
                <h6>Actividad económica</h6>
                <h6>{{creditoPreaprobado.actividadEconomica}}</h6>
            </div>
        </div>
        <div class="pasos">
            <div class="paso2">
                <div class="circle">
                    <div>1</div>
                </div>
                <h6 style="margin-top: 1vh">Actualizar</h6>
                <h6 style="text-align: center;">datos</h6>
            </div>
            <hr />
            <div class="paso1">
                <div class="circle">
                    <div>2</div>
                </div>
                <h6 style="margin-top: 1vh; padding-left: 0.3em;">Datos para</h6>
                <h6>desembolso</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>3</div>
                </div>
                <h6 style="margin-top: 1vh">Datos del</h6>
                <h6 style="text-align: center;">seguro</h6>
            </div>
        </div>
        <div class="texto" style="margin-top: 3vh">
            <h6>Dinos en qué entidad bancaria y número de cuenta se realizará el desembolso.
            </h6>
        </div>
        <form [formGroup]="formSolicitarCreditos1" (ngSubmit)="onSubmit()">
            <div class="field">
                <h5
                    *ngIf="bancoForm.get('banco').invalid && (bancoForm.get('banco').touched || bancoForm.get('banco').dirty || errorSubmit ) && !errorDaviPlata">
                    *Por favor seleccione un banco.
                </h5>
                <h5
                    *ngIf="bancoForm.get('banco').invalid && (bancoForm.get('banco').touched || bancoForm.get('banco').dirty || errorSubmit ) && errorDaviPlata">
                    *Al seleccionar como tipo de cuenta DaviPlata, debe seleccionar como entidad bancaria BANCO
                    DAVIVIENDA S.A.
                </h5>
                <label>Entidad bancaria<span style="color: red; font-weight: 500;">*</span></label>
                <div class="entidadBancaria">
                    <select formControlName="banco" id="sel" style="color: #757575; width: calc(100% - 2.5em);"
                        onclick="document.getElementById('sel').style.color='black'">
                        <option [ngValue]="null" selected disabled hidden>Selecciona</option>
                        <option *ngFor="let banco of entidadBancaria" [ngValue]="banco.nombre">{{banco.etiqueta}}
                        </option>
                    </select>
                    <button class="info" (click)="infoTopes()" type="button">i</button>
                </div>
            </div>
            <div class="field">
                <h5
                    *ngIf="bancoForm.get('tipoCuenta').invalid && (bancoForm.get('tipoCuenta').touched || bancoForm.get('tipoCuenta').dirty || errorSubmit )">
                    *Por favor seleccione un tipo de cuenta.
                </h5>
                <label>Tipo de cuenta<span style="color: red; font-weight: 500;">*</span></label>
                <select formControlName="tipoCuenta" id="sel2" style="color: #757575"
                    onclick="document.getElementById('sel2').style.color='black'" (change)="validacionDaviplata()">
                    <option [ngValue]="null" selected disabled hidden>Selecciona</option>
                    <option *ngFor="let cuenta of tipodeCuenta" [ngValue]="cuenta.nombre">{{cuenta.etiqueta}}
                    </option>
                </select>
            </div>
            <div class="field">
                <h5
                    *ngIf="bancoForm.get('tipoCuenta').value === 'DaviPlata' || bancoForm.get('banco').value === 'GANA'">
                    *Para el campo Número de Cuenta, recuerda ingresar el número de celular, si el desembolso es por
                    Daviplata o Gana.
                </h5>
                <h5
                    *ngIf="bancoForm.get('numeroCuenta').invalid && (bancoForm.get('numeroCuenta').touched || bancoForm.get('numeroCuenta').dirty || errorSubmit )">
                    *Por favor valide el número de cuenta, no se permiten secuencias alfanuméricas.
                </h5>
                <label>Número de cuenta<span style="color: red; font-weight: 500;">*</span></label>
                <input formControlName="numeroCuenta" placeholder="Ingresa cuenta bancaria">
            </div>
            <div class="buttons">
                <button class="cancelar" type="button" (click)="regresar()">Regresar</button>
                <button class="continuar" type="submit">Continuar</button>
            </div>
        </form>
    </div>
</div>

<div class="message" *ngIf="messageBack">
    <div class="card">
        <button class="x" (click)="continuarSolicitud()">X</button>
        <img src="../../../../assets/Images/warning.svg">
        <h6>Estás a punto de retornar al paso inicial, recuerda que tendrás que volver a llenar los datos
            personales.</h6>
        <h6>¿Estás seguro de que deseas regresar?</h6>
        <div class="buttons">
            <button class="continuar" (click)="continuarSolicitud()">Continuar solicitud</button>
            <button class="cancelar" (click)="confirmarRegresar()">Si, regresar</button>
        </div>
    </div>
</div>