import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import * as CryptoJS from "crypto-js";

import { saveSolicitarCredito } from '../state/solicitar-credito.actions';
import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { environment } from "../../../../environments/environment"
import { StateCreditos } from 'src/app/constants/interfaces/solicitar-credito.interface';

@Component({
  selector: 'app-datos-desembolso',
  templateUrl: './datos-desembolso.component.html',
  styleUrls: ['./datos-desembolso.component.css']
})
export class DatosDesembolsoComponent implements OnInit {
  entidadBancaria: { etiqueta: string, nombre: string, tope: number | null }[];
  tipodeCuenta: [];
  errorSubmit: boolean = false;
  errorDaviPlata: boolean = false;
  messageBack: boolean = false;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  @Output() editPaso = new EventEmitter<boolean>();
  @Output() editForm1 = new EventEmitter<{ banco: string, tipoCuenta: string, numeroCuenta: string }>();
  @Input() creditoPreaprobado: { codigoProducto: number, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, simulador: number | null, idMiembroCampania: string | null };
  dataForm1: { banco: string, tipoCuenta: string, numeroCuenta: string } | null;
  dataForm1$: Observable<any> = this.store.select(state => state.solicitarCredito.solicitarCreditoStep1);
  formSolicitarCreditos1: FormGroup;

  constructor(private messageService: MessagesService, private router: Router, private store: Store<StateCreditos>) {
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
    this.formSolicitarCreditos1 = new FormGroup({
      banco: new FormControl(null, [Validators.required]),
      tipoCuenta: new FormControl(null, [Validators.required]),
      numeroCuenta: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
    });
    const valores = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.entidadBancaria = (valores[4].valores)
    this.tipodeCuenta = valores[2].valores;
    this.dataForm1$.pipe(take(1)).subscribe(data => this.dataForm1 = data);
  }

  ngOnInit(): void {
    const valores = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.entidadBancaria = (valores[4].valores).filter(banco => (banco.tope === null || banco.tope >= this.creditoPreaprobado.monto)); //Mostrar sólo los bancos cuyos bancos estén dentro del tope
  }

  nextPaso() {
    this.editPaso.emit(true);
  }

  onSubmit() {
    if (this.formSolicitarCreditos1.valid) {
      this.store.dispatch(saveSolicitarCredito({ form: this.formSolicitarCreditos1.value }));
      this.errorSubmit = false;
      this.editPaso.emit(true);
    }
    else {
      this.errorSubmit = true;
    }
  }

  regresar() {
    this.messageBack = true;
  }

  continuarSolicitud() {
    this.messageBack = false;
  }

  confirmarRegresar() {
    this.router.navigate(["/"]);
  }

  validacionDaviplata() {
    //Si se selecciona como tipo de cuenta DaviPlata se debe de cambiar la validación del banco, indicando que se debe de seleccionar BANCO DAVIVIENDA S.A. y para el numero de cuenta también, se debe de ingresar un número de celular el cual tiene 10 digitos
    if (this.formSolicitarCreditos1.value.tipoCuenta === "DaviPlata") {
      this.formSolicitarCreditos1.controls.banco.setValidators([Validators.required, Validators.pattern(/\bBANCO DAVIVIENDA S.A./g)])
      this.formSolicitarCreditos1.controls.banco.updateValueAndValidity();
      this.formSolicitarCreditos1.controls.numeroCuenta.setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])
      this.formSolicitarCreditos1.controls.numeroCuenta.updateValueAndValidity();
      this.errorDaviPlata = true;
    }
    else {
      this.formSolicitarCreditos1.controls.banco.setValidators([Validators.required])
      this.formSolicitarCreditos1.controls.banco.updateValueAndValidity();
      this.formSolicitarCreditos1.controls.numeroCuenta.setValidators([Validators.required, Validators.pattern('^[0-9]*$')])
      this.formSolicitarCreditos1.controls.numeroCuenta.updateValueAndValidity();
      this.errorDaviPlata = false;
    }
  }

  infoTopes() {
    this.messageService.visibleMessage({
      imgName: "info.svg",
      texto1: "Algunos bancos poseen un valor máximo de desembolso.",
      texto2: 'Sólo podrás seleccionar los bancos que se ajustan a tu monto solicitado.',
      button: "Aceptar",
    }, "Info");
  }

  get bancoForm() { return this.formSolicitarCreditos1 }
}