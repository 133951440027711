<div mat-dialog-title>
    <h6>Pago manual</h6>
    <button mat-dialog-close>X</button>
</div>
<div mat-dialog-content>
    <p>
        Sí vas a realizar tu pago de forma manual, puedes dirigirte a alguno de los siguientes
        <span style="color: var(--secondary); text-decoration: underline; cursor: pointer;" (click)="openImage()">
            puntos de pago.
        </span>
    </p>
</div>
<mat-dialog-actions align="center" class="buttonsPagoManual">
    <button mat-button mat-dialog-close class="closeButton">Cerrar</button>
    <button mat-button class="closeButton" (click)="openImage()" style="background-color: #ffd100;">
        Ver puntos de pago
    </button>
</mat-dialog-actions>