import { HostListener, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as CryptoJS from "crypto-js";
import { AppService } from "../../app.service";
import { LoadingInfoService } from "../../reusableComponents/loading-info/loading-info.service";
import { HomeService } from "../home/home.service";
import { environment } from "../../../environments/environment";
import { MsalService } from '@azure/msal-angular';
import { MessagesService } from "../../reusableComponents/messages/messages.service";
import { saveCreditoPreaprobado } from "./state/solicitar-credito.actions";
import { SolicitarCreditoInterface } from '../../constants/interfaces/solicitar-credito.interface';
@Component({
  selector: 'app-solicitar-credito',
  templateUrl: './solicitar-credito.component.html',
  styleUrls: ['./solicitar-credito.component.css']
})
export class SolicitarCreditoComponent implements OnInit {
  paso: boolean = false; //Boolean para decidir si muestra el formulario 1 o el 2.
  creditoPreaprobado: {};
  loadingInfo: boolean = false; //Bandera del cargando
  loadingSubscription: Subscription;
  dataForm1: { banco: string, tipoCuenta: string, numeroCuenta: string } | null = null;

  constructor(private homeService: HomeService, private authService: MsalService, private loadingService: LoadingInfoService, private messageService: MessagesService, private appService: AppService, private store: Store<SolicitarCreditoInterface>, private router: Router) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(value => this.loadingInfo = value);
    this.creditoPreaprobado = this.homeService.fetchCreditoPreaprobado();
    if (this.creditoPreaprobado === undefined) {
      this.creditoPreaprobado = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("object"), environment.constantKey).toString(CryptoJS.enc.Utf8))
    }
    this.store.dispatch(saveCreditoPreaprobado({ data: this.creditoPreaprobado }));
  }

  @HostListener('window:focus', ['$event'])
  tabActivation(event) {
    let getAccount = this.authService.getAccount();
    let sub  = getAccount.idTokenClaims.sub
    this.appService.getUserAttributeSesion(sub).subscribe(
      data => {
        let sesionAtrributes = (data.atrributes[0].Sesion);
        let sesion = sessionStorage.getItem('Sesion');
        if( (getAccount == null  ||  sesion != sesionAtrributes)){
          this.ErrorCerrarSesionPorDobleSesion()
        }

      },
      error => {}
      )
  }

  ErrorCerrarSesionPorDobleSesion() {
    this.messageService.visibleMessage(
      {
        imgName: "error.svg",
        texto1: `Sesión invalida`,
        texto2: "Al parecer ya tienes una sesión abierta, cierra la sesión e inicia de nuevo para continuar.",
        button: "Cerrar sesión"
      },
      "CerrarSesion"
    )
  }

  ngOnInit(): void {
    this.loadingService.stopLoading()
  }

  updatePaso(val: boolean) {
    this.paso = val;
    if(this.paso) {
      this.router.navigate(["/solicitar-credito-2"]);
    } else {
      this.router.navigate(["/solicitar-credito-1"]);
    }
  }

  updateForm1(form1: { banco: string, tipoCuenta: string, numeroCuenta: string }) {
    this.dataForm1 = form1;
  }
}
