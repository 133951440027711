import { HostListener, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { Subscription } from 'rxjs';
import * as CryptoJS from "crypto-js";

import { LoadingInfoService } from "../../reusableComponents/loading-info/loading-info.service";
import { HomeService } from "../home/home.service";
import { environment } from "../../../environments/environment";
import { MsalService } from '@azure/msal-angular';
import { MessagesService } from "../../reusableComponents/messages/messages.service";
import { AppService } from "../../app.service";
import { saveCreditoPreaprobadoSinPreaprobado } from "./state/solicitar-credito-sin-preabrobado.actions";
import { StateCreditos } from 'src/app/constants/interfaces/solicitar-credito.interface';
@Component({
  selector: 'app-solicitarcredito-sinpreaprobado-cambiomonto',
  templateUrl: './solicitarcredito-sinpreaprobado-cambiomonto.component.html',
  styleUrls: ['./solicitarcredito-sinpreaprobado-cambiomonto.component.css']
})

export class SolicitarcreditoSinpreaprobadoCambiomontoComponent implements OnInit {
  paso: number = 1; //Number para decidir cual formulario se muestra.
  creditoPreaprobado: {};
  dataFormDesembolso: { banco: string, tipoCuenta: string, numeroCuenta: string } | null = null;
  dataFormActualizarDatos: { estadoCivil: string, tipoVivienda: string, actividadEconomica: string };
  dataFormSeguro: { nombreCompleto: string, tipoDocumento: string, numeroDocumento: string, parentesco: string, porcentaje: number }[];
  loadingInfo: boolean = false; //Bandera del cargando
  loadingSubscription: Subscription;

  constructor(private homeService: HomeService, private authService: MsalService, private loadingService: LoadingInfoService, private messageService: MessagesService, private appService: AppService, private store: Store<StateCreditos>, private router: Router) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(value => this.loadingInfo = value);
    this.creditoPreaprobado = this.homeService.fetchCreditoPreaprobado();
    if (this.creditoPreaprobado === undefined) {
      this.creditoPreaprobado = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("object"), environment.constantKey).toString(CryptoJS.enc.Utf8))
    }
    this.store.dispatch(saveCreditoPreaprobadoSinPreaprobado({ data: this.creditoPreaprobado }));
  }

  @HostListener('window:focus', ['$event'])
  tabActivation(event) {
    let getAccount = this.authService.getAccount();
    let sub  = getAccount.idTokenClaims.sub
    this.appService.getUserAttributeSesion(sub).subscribe(
      data => {
        let sesionAtrributes = (data.atrributes[0].Sesion);
        let sesion = sessionStorage.getItem('Sesion');
        //Se valida la pestaña original donde se inicio sesión.
        if( (getAccount == null  ||  sesion != sesionAtrributes)){
          this.ErrorCerrarSesionPorDobleSesion()
        }

      },
      error => {}
      )
  }

  ErrorCerrarSesionPorDobleSesion() {
    this.messageService.visibleMessage(
      {
        imgName: "error.svg",
        texto1: `Sesión invalida`,
        texto2: "Al parecer ya tienes una sesión abierta, cierra la sesión e inicia de nuevo para continuar.",
        button: "Cerrar sesión"
      },
      "CerrarSesion"
    )
  }

  ngOnInit(): void {
    this.loadingService.stopLoading()
  }

  updatePaso(val: number) {
    this.router.navigate([`/cambiar-monto-${val}`]);
  }

  updateFormDesembolso(formDesembolso: { banco: string, tipoCuenta: string, numeroCuenta: string }) {
    this.dataFormDesembolso = formDesembolso;
  }

  updateFormActualizarDatos(formActualizarDatos: { estadoCivil: string, tipoVivienda: string, actividadEconomica: string, telefonoArrendador: string, nombreArrendador: string }) {
    this.dataFormActualizarDatos = formActualizarDatos;
  }

  updateFormSeguro(formSeguro: { nombreCompleto: string, tipoDocumento: string, numeroDocumento: string, parentesco: string, porcentaje: number }[]) {
    this.dataFormSeguro = formSeguro;
  }
}
