<div *ngIf="messageService._messageVisible" class="messagesContainer">
  <div class="card">
    <button
      class="x"
      *ngIf="
        messageService.tipoModal2 != 'Close2' &&
        messageService.tipoModal != 'CerrarSesion'
      "
      (click)="messageService.invisibleMessageClose()"
    >
      X
    </button>
    <img src="{{ messageService.message.img }}" />
    <h6 *ngIf="messageService.message.title" style="font-weight: 500">
      {{ messageService.message.title }}
    </h6>
    <h6>{{ messageService.message.texto1 }}</h6>
    <h6 *ngIf="messageService.message.texto2">
      {{ messageService.message.texto2 }}
    </h6>
    <h6
      class="text3"
      *ngIf="
        messageService.message.texto3 &&
        messageService.message.msnintentosValidacion
      "
    >
      {{ messageService.message.texto3 }}
    </h6>

    <h6 *ngIf="messageService.message.msnJuridicial">
      Para continuar el proceso de registro y autenticación de tu usuario envía
      al siguiente correo:
      <strong style="color: #007bff"
        >autenticacionesov@interactuar.org.co</strong
      >
      la copia del certificado de existencia y representación de la persona
      jurídica actualizado en los últimos 30 días y copia de la cédula
      representante legal o apoderado con su respectiva autorización firmada por
      quien corresponda, serás contactado en el menor tiempo posible. Con el
      envío de esta información se aceptan las políticas de tratamiento de datos
      de la Corporación Interactuar, que se encuentran publicadas en la página:
      <a href="https://interactuar.org.co/" target="_blank"
        >www.interactuar.org.co</a
      >
    </h6>

    <h6 *ngIf="messageService.message.msnServiceError">
      En este momento el servicio no está disponible, intenta más tarde,
      comunícate al WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o dirígete a la oficina más cercana
      <a href="https://interactuar.org.co/oficinas/" target="_blank"
        >https://interactuar.org.co/oficinas.</a
      >
      Agradecemos tu paciencia y confianza en INTERACTUAR.
    </h6>

    <h6 *ngIf="messageService.message.msnBloquaUsurio">
      La validación de tus datos no fue exitosa. <br /><br />
      Hemos bloqueado tu usuario para garantizar la seguridad de tus datos.
      <br />
      Comunícate con nosotros para desbloquearlo, llamando al
      <strong style="color: #007bff">018000417019</strong>, escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o dirígete a una de nuestras oficinas más cercanas.
    </h6>

    <h6 *ngIf="messageService.message.msnintentosValidacion">
      "Tienes otro intento para validar identidad o de lo contrario, por tu
      seguridad tu usuario será bloqueado."
    </h6>

    <h6 *ngIf="messageService.message.msnvencidoValidacion">
      Código verificación ha vencido. <br />
      Para obtener un nuevo código selecciona la opción
      <a [routerLink]="" (click)="reenviarCodigo()">clic aquí</a>
    </h6>

    <h6 *ngIf="messageService.message.msnNoficacionFallida">
      Hemos detectado una falla en la notificación del código de verificación a
      tu numero de celular
      <h6 *ngIf="registroinicial">y/o correo electrónico</h6>
      registrado en nuestra base de datos. Comunícate con nosotros llamando al
      <strong style="color: #007bff">018000417019</strong>, escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o dirígete a una de nuestras oficinas más cercanas.
    </h6>

    <h6 *ngIf="messageService.message.msncreditos">
      Generación certificados temporalmente fuera de servicio. Comunícate con
      nosotros llamando al <strong style="color: #007bff">018000417019</strong>,
      escríbenos al WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o dirígete a una de nuestras oficinas más cercanas.
    </h6>

    <h6 *ngIf="messageService.message.msnSimulador">
      Si actualmente tienes créditos vigentes con la Corporación y tienes saldo
      pendiente por cancelar,
      <strong>súmalo al valor que deseas solicitar,</strong> de lo contrario da
      clic en <strong>Cerrar o X</strong> y continua con tu simulación.
      <br /><br />
      En caso de tener saldo pendiente y no sabes cual es el valor ingresa a
      sección <a style="color: #007bff" href="#">Mis créditos vigentes</a> o
      comunícate con nosotros llamando al
      <strong style="color: #007bff">018000417019</strong>, escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o dirígete a una de nuestras oficinas más cercanas.
    </h6>

    <h6 *ngIf="messageService.message.msnCreditoCancelado"></h6>

    <h6 *ngIf="messageService.message.msnCreditosSelect">
      Comunícate con nosotros para dar solución, llamando al
      <strong style="color: #007bff">018000417019</strong>, escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      <br /><strong> o inténtalo más tarde.</strong>
    </h6>

    <h6 *ngIf="messageService.message.msnCreditosJuridico">
      No es posible generar el certificado para el crédito
      <strong>{{ messageService.message.parametro }}</strong
      >, sólo lo podrá solicitar a través de nuestra línea
      <strong style="color: #007bff">018000417019</strong>, escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
      o acércate a nuestra oficina más cercana
    </h6>

    <h6 *ngIf="messageService.message.msnCreditosinregistro">
      <strong style="font-weight: 500"
        >No encontramos información asociada a tu crédito
        {{ messageService.message.parametro }}. <br />Por lo tanto, no es
        posible generar este reporte.</strong
      >
      <br />
      <br />
      Si tienes dudas, comunícate con nosotros, llamando al
      <strong style="color: #007bff">018000417019</strong> o escríbenos al
      WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
    </h6>

    <h6 class="text3" *ngIf="messageService.message.whatsapp">
      escríbenos al WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
        >320 606 4004</a
      >
    </h6>
    <a
      *ngIf="messageService.message.enlace"
      [href]="messageService.message.enlace"
      target="_blank"
      >{{ messageService.message.enlace }}</a
    >
    <button class="continuar" (click)="messageService.invisibleMessage()">
      {{ messageService.message.button }}
    </button>
    <button
      class="cancelar"
      *ngIf="messageService.message.button2"
      (click)="messageService.invisibleMessage2()"
    >
      {{ messageService.message.button2 }}
    </button>
  </div>
</div>
