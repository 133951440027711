import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable, forkJoin, throwError } from "rxjs";
import * as moment from 'moment';
import * as CryptoJS from "crypto-js";

import { OAuth2, estadoCartera, ParametrosCreditos, parametrosGenerales, respuestaActualizarDatos, resultadoGetCreditos, resultadoCreditos, deuda, creditosAnoGrabable, creditosDetalle, ConsultaEstadoCarteraCredito } from "../../constants/interfaces/salesforceInterfaces";
import { initialSalesforce, B2C, oracle, onCredit, SIIF, ORACLE } from "../../constants/api/end-points";
import { environment } from "../../../environments/environment";
import { salesforceEncabezado } from "../../constants/api/encabezados";

@Injectable({ providedIn: 'root' })

export class HomeService {
  private creditoPreaprobado: { codigoProducto: string, codigoProductoInicial: string, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, idMiembroCampania: string | null, actividadEconomica: string, famiempresa: string, tipoCredito: string, fuente: string, montoInicial: number, plazoInicial: number, tipoRespuesta?: string, solicitaAqui?:boolean };
  tasaInteres = 0

  constructor(private http: HttpClient) { }

  saveCreditoPreaprobado(objeto) {
    localStorage.setItem("object", CryptoJS.AES.encrypt(JSON.stringify(objeto), environment.constantKey).toString());
    this.creditoPreaprobado = objeto;
  }

  fetchCreditoPreaprobado() {
    return this.creditoPreaprobado;
  }

  updateTokens(): Observable<any> {
    return this.http.post<any>(
      B2C.tokenApiManagment,
      { "secret": environment.secretTokenB2C, "clientId": environment.clientIdTokenB2C },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey }), responseType: 'text' as 'json' }
    ).pipe(
      mergeMap(tokenB2C => this.http.post<OAuth2>(
        initialSalesforce.OAuth2,
        null,
        { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': tokenB2C }) }
      )
        .pipe(
          tap(data => { localStorage.setItem('tokenSalesforce', data.access_token); localStorage.setItem('tokenB2C', tokenB2C) }), //the RxJS tap() operator, which looks at the observable values, does something with those values, and passes them along. The tap() call back doesn't touch the values themselves.
          catchError(e => throwError({ origin: 'obteniendo tokensalesForce', error: e }))
        )
      ),
      catchError(e => throwError({ origin: 'obteniendo token B2C', error: e }))
    )
  }

  getParametrosCreditos(tokenSalesforce: string, tokenB2C: string, tipoDocumento: string, numeroDocumento: string): Observable<any> {
    let parametrosGenerales = this.http.post<parametrosGenerales>(
      initialSalesforce.parametrosGenerales,
      { "encabezado": salesforceEncabezado("Parámetros generales Autogestión") },
      { headers: new HttpHeaders({ 'Header-SF': tokenSalesforce, 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}` }) }
    ).pipe(
      tap(data => {
        const dataToSave = [...data.parametrosGeneralesAutogestion.listas, { "date": moment().format('YYYY-MM-DD') }];
        localStorage.setItem('paramGen', CryptoJS.AES.encrypt(JSON.stringify(dataToSave), environment.constantKey).toString())
      }),
      catchError(e => throwError({ origin: 'consulta parámetros generales', error: e }))
    );

    let hash = this.encrypt('Créditos y estados Autogestión'+','+numeroDocumento)

    let CreditosEstados = this.http.post<ParametrosCreditos>(
      initialSalesforce.creditosEstados,
      {
        "encabezado": salesforceEncabezado("Créditos y estados Autogestión"),
        "creditosEstadosAutogestion": {
          "tipoDocumento": tipoDocumento, /*"Cedula de Ciudadania",*/ "numeroDocumento": numeroDocumento /*numeroDocumento*/ /* 15491091 */
        }
      },
      { headers: new HttpHeaders({
        'Header-SF': tokenSalesforce, 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}`,
        'hash':hash }) }
    ).pipe(
      catchError(e => throwError({ origin: 'consulta créditos y estados', error: e }))
    );

    // Si existe en el localStorage un elemento paramGen Y la fecha de este (se encuentra en la última posición de paramGen) es igual a la fecha actual NO SE TIENE QUE CONSULTAR paramGen de lo contrario SI se tiene que consultar
    if (localStorage.getItem('paramGen') && moment().format('YYYY-MM-DD') === JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8))[JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8)).length - 1].date) {
      return CreditosEstados;
    }
    else {
      return forkJoin([
        parametrosGenerales,
        CreditosEstados
      ]);
    }
  }

  simulator(producto: number, monto: number, plazo: number, tokenB2C: string, tipoDocumento: string, numeroDocumento: string):
    Observable<{ Cargos: number, Cuota: number, Estado: string, Mensaje: string, Monto: number, Plazo: number, Producto: number, Total: number }> {
    return this.http.post<{ Cargos: number, Cuota: number, Estado: string, Mensaje: string, Monto: number, Plazo: number, Producto: number, Total: number }>(
      oracle.simulador,
      {
        "producto": producto,
        "monto": monto,
        "plazo": plazo,
        "TipoIdentificacion": tipoDocumento,
        "Identificacion": parseInt(numeroDocumento)
      },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}` }) }
    ).pipe(
      catchError(e => throwError({ origin: 'consulta simulador', error: e }))
    )
  }

  estadoCartera(credito: string, tokenB2C: string): Observable<estadoCartera> {
    let hash = this.encrypt('consulta estado cartera'+','+credito)

    return this.http.post<estadoCartera>(
      initialSalesforce.estadoCarteraCliente,
      {
        "encabezado": salesforceEncabezado("consulta estado cartera"),
        "cuerpo": {
          "activo": { "numeroCredito": credito }
        }
      },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}`,
      'hash':hash }) }
    ).pipe(
      catchError(e => throwError({ origin: "consulta estado cartera", error: e }))
    )
  }


  obtenerCreditos(tipoDocumento: string, documemento: string): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('CONSULTA CREDITOS VIGENTES/CANCELADOS CLIENTE SALESFORCE'+','+documemento)
    return this.http.post<resultadoCreditos>(
      initialSalesforce.creditosVigentesSalesforce + '/CreditosVigentes',
      {
        "request": {
          "encabezado": {
            "fecha": fecha,
            "integracion": "CONSULTA CREDITOS VIGENTES/CANCELADOS CLIENTE SALESFORCE",
            "origen": "MICROSITIO",
            "destino": "SALESFORCE"
          },
          "creditosVigCanClienteSalesForce": {
            "tipoId": tipoDocumento,
            "nroId": documemento
          }
        }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'Header-SF': localStorage.getItem('tokenSalesforce'),
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  consultarCreditoPazysalvo(credito: string, tipoDocumento: string, documemento: string, url: string): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('CERTIFICADO PAZ y SALVO ORACLE PDF'+','+credito)
    return this.http.post<any>(
      ORACLE.pdfOracle.replace('/qa/','/pru/') + 'pazysalvo/pdf/v1-0',
      {
        "request": {
            "encabezado": {
                "fecha": fecha,
                "integracion": "CERTIFICADO PAZ y SALVO ORACLE PDF",
                "origen": "MICROSITIO",
                "destino": "ORACLE"
            },
            "certificadoPazySalvoORACLE": {
                "credito": credito,
                "tipoId": tipoDocumento,
                "nroId": documemento
            }
        }
    },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  getAnoGrabable(ano: number, credito: string): Observable<any> {
    let fecha = new Date().toISOString()

    return this.http.post<any>(
       ORACLE.pdfOracle.replace('/qa/','/pru/') +'aniogravable/pdf/v1-0',
        {
          "request": {
              "encabezado": {
                  "fecha": fecha,
                  "integracion": "CERTIFICADO AÑO GRAVABLE ORACLE PDF",
                  "origen": "MICROSITIO",
                  "destino": " ORACLE"
              },
              "certificadoAnioGravableORACLE": {
                  "credito": credito,
                  "anio": ano
              }
          }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )

  }

  consultarCreditoDetalle(credito: string, tipoDocumento: string, documemento: string, tokenSIIF: string): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('estadodecuenta'+','+credito)
    return this.http.post<creditosDetalle>(
      SIIF.estadoCredito,
      {
        "encabezado": {
            "origen": "MICROSITIO",
            "destino": "SIIF",
            "integracion": "CONSULTAPDF",
            "fecha": fecha
        },
        "NombreReporte": "estadodecuenta",
        "canalDestino": "MICROSITIO",
        "parametrosReportes": [
            {
                "nombreParametro": "Numerodecredito",
                "valorParametro": credito
            },
            {
                "nombreParametro": "indicador",
                "valorParametro": "1"
            }
        ]
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  consultarCreditoDetalleAnoGravable(credito: string, tipoDocumento: string, documemento: string, tokenSIIF: string, anio:number): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('AñoGravable'+','+credito)
    return this.http.post<creditosDetalle>(
      SIIF.estadoCredito,
      {
        "encabezado": {
            "origen": "MICROSITIO",
            "destino": "SIIF",
            "integracion": "DOCUMENTOSPDF",
            "fecha": fecha
        },
        "nombreReporte": "AñoGravable",
        "canalDestino": "MICROSITIO",
        "parametrosReportes": [
            {
                "nombreParametro": "NumeroPrestamo",
                "valorParametro": credito
            },
            {
                "nombreParametro": "PeriodoFiscal",
                "valorParametro": '' + anio + ''
            }
        ]
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  consultarCreditoDetallePazYSalvo(credito: string, tipoDocumento: string, documemento: string, tokenSIIF: string): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('PAZYSAL'+','+credito)
    const options = {
      timeout: 600000 // Tiempo de timeout en milisegundos (en este caso, 60 segundos)
    };

    return this.http.post<creditosDetalle>(
      SIIF.deudaPazYSalvo,
      {
        "request": {
          "encabezado": {
              "origen": "MICROSITIO",
              "destino": "SIIF",
              "integracion": "DOCUMENTOSPDF",
              "fecha": fecha
          },
          "nombreDocumento": "PAZYSAL",
          "parametrosReportes": [
              {
                  "nombreParametro": "NumeroDeCredito",
                  "valorParametro": credito
              }
          ]
        }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  consultarCreditoDetalleEstadoDeuda(credito: string, tipoDocumento: string, documemento: string, tokenSIIF: string): Observable<any> {
    let fecha = new Date().toISOString()
    let hash = this.encrypt('CERESTCX'+','+credito)
    return this.http.post<creditosDetalle>(
      SIIF.deudaPazYSalvo,
      {
        "request": {
          "encabezado": {
              "origen": "MICROSITIO",
              "destino": "SIIF",
              "integracion": "DOCUMENTOSPDF",
              "fecha": fecha
          },
          "nombreDocumento": 'CERESTCX',
          "parametrosReportes": [
              {
                  "nombreParametro": "NumerodeCredito",
                  "valorParametro": credito
              },
              {
                  "nombreParametro": "Indicador",
                  "valorParametro": "1"
              }
          ]
      }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: e, error: e }))
    )
  }

  getTokenSIIF(): Observable<any> {
    var fecha = new Date().toLocaleString('es-CL', { timeZone: 'America/Bogota' })

    return this.http.post<any>(
      SIIF.tokenSIIF,
      {},
      {
        headers: new HttpHeaders({
          'Ocp-Apim-Subscription-Key1': environment.SubscriptionKey,
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`

        })
      }
    ).pipe(
      catchError(e => throwError({ origin: 'Consulta Token SIIF', error: e }))
    )
  }

  getEstadoCarteraCredito(credito: string, tokenSIIF): Observable<ConsultaEstadoCarteraCredito> {
    let fecha = moment().format()
    let hash = this.encrypt('consulta estado cartera'+','+credito)
    return this.http.post<ConsultaEstadoCarteraCredito>(
      SIIF.estadoCarteraCredito,
      {
        "encabezado": {
          "fecha": fecha,
          "identificador": "0",
          "integracion": "consulta estado cartera",
          "operacion": "0",
          "origen": "MICROSITIO",
          "destino": "SIIF"
        },
        "cuerpo": {
          "activo": {
            "numeroCredito": credito
          }
        }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: 'Consulta Consulta Estado Cartera', error: e }))
    )
  }
  consultaTasaCampaña(tokenSIIF: any, campana: any = '', solicitaAqui:boolean = false) {
    campana = campana.toUpperCase()
    let fecha = new Date().toISOString()
    if (campana == '' || campana == null) {
      if((localStorage.getItem('IdCampanaSoliciteAqui') == '' || localStorage.getItem('IdCampanaSoliciteAqui') == 'null') && !solicitaAqui){
        campana = localStorage.getItem('IdCampanaPreaprobado')
      }else{
        campana = localStorage.getItem('IdCampanaSoliciteAqui')
      }
    }
    let hash = this.encrypt('CONSULTA TASA CAMPAÑA SIIF'+',' + campana)
    return this.http.post<any>(
      SIIF.consultarCampana + 'v1.0.0/WSGYG67REST_INT/ConsultaTasaCampaña',
      {
        "request": {
          "encabezado": {
            "origen": "MICROSITIO",
            "destino": "SIIF",
            "integracion": "CONSULTA TASA CAMPAÑA SIIF",
            "fecha": fecha
          },
          "consultaTasaCampanaSiif": {
            "campana": {
              "Codigo": campana
            }
          }
        }
      },
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'TokenBearer-SIIF': tokenSIIF,
          'hash':hash
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: 'Consulta Tasa', error: e }))
    )
  }

  calcularCuota(valor: number, plazo: number, tasa: any = '') {
    if (tasa) {
      this.tasaInteres = parseFloat(tasa)
    }
    let tasaInteres = parseFloat(tasa.replace(',', '.'))
    tasaInteres = tasaInteres / 100;
    let TasaMV =  (Math.pow((1+tasaInteres), (1/12)) - 1);
    let numerador =  (TasaMV * (Math.pow((1+TasaMV), plazo)))
    let denominador = (Math.pow((1+TasaMV), plazo) -1)
    let cuotafinal = valor * (numerador / denominador)
    return Math.round(cuotafinal);
  }

  getPDF(IdDocumento: string): Observable<any> {
    return this.http.get<any>(
      environment.UrlJsonServer + '/certificados?id=0000481',
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}` }) }
    ).pipe(
      catchError(e => throwError({ origin: "consulta estado cartera", error: e }))
    )
  }

  getToken(): Observable<any> {
    return this.http.get<any>(
      environment.UrlJsonServer + '/tokenSIIF/Token1'
    ).pipe(
      catchError(e => throwError({ origin: "Token SIIF", error: e }))
    )
  }

  setToken(token): Observable<any> {
    var fecha = new Date().toLocaleString('es-CL', { timeZone: 'America/Bogota' })
    return this.http.put<any>(
      environment.UrlJsonServer + '/tokenSIIF/Token1',
      {
        "request": {
        "IdDocumento": "Token1",
        "token":token,
        "fecha":fecha
        }
    }
    ).pipe(
      mergeMap(data => this.http.post<any>(
        environment.UrlJsonServer + '/historyTokeSIIF',
        {
          "request": {
            "IdDocumento": fecha
        },
        "fecha": fecha,
        "token": data
        }
      ),
      catchError(e => throwError({ origin: "Token SIIF", error: e }))
    ))
  }

  getPDFSIIF(idDocumento): Observable<any> {
    return this.http.get<any>(
      environment.UrlJsonServer + '/certificados?request.idDocumento=' + idDocumento
    ).pipe(
      catchError(e => throwError({ origin: "consulta estado cartera", error: e }))
    )
  }

  deletePDFSIIF(idDocumento): Observable<any> {
    return this.http.delete<any>(
      environment.UrlJsonServer + '/certificados/' + idDocumento
    ).pipe(
      catchError(e => throwError({ origin: "consulta estado cartera", error: e }))
    )
  }

  encrypt(value: string): string {
    const cipher = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(environment.Semillasecreto), {
      iv: CryptoJS.enc.Utf8.parse(environment.Semillainicialización),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })

    return cipher.toString()
  }

}
