import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

import { environment } from "../../../environments/environment"
import { oracle } from "../../constants/api/end-points";

@Injectable({
  providedIn: 'root'
})
export class CambiarMontoService {

  constructor(private http: HttpClient) { }

  simulator(producto: number, monto: number, plazo: number, tokenB2C: string, tipoDocumento: string, numeroDocumento: string): Observable<{ Cargos: number, Cuota: number, Estado: string, Mensaje: string, Monto: number, Plazo: number, Producto: number, Total: number }> {
    return this.http.post<{ Cargos: number, Cuota: number, Estado: string, Mensaje: string, Monto: number, Plazo: number, Producto: number, Total: number }>(
      oracle.simulador,
      {
        "producto": producto,
        "monto": monto,
        "plazo": plazo,
        "TipoIdentificacion": tipoDocumento,
        "Identificacion": parseInt(numeroDocumento)
      },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}` }) }
    ).pipe(
      catchError(e => throwError({ origin: 'simulador de crédito', error: e }))
    )
  }
}
