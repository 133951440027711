import { environment } from "../../../environments/environment";

export const B2C = {
    userFlowsLogin: `https://${environment.urlbaseB2C}.b2clogin.com/${environment.urlbaseB2C}.onmicrosoft.com/B2C_1_AUTOGESTION_LoginRegistro`,
    userFlowRecoverPassword: `https://${environment.urlbaseB2C}.b2clogin.com/${environment.urlbaseB2C}.onmicrosoft.com/B2C_1_AUTOGESTION_RecuperarContrasena`,
    b2cScopes: 'https://interactuarb2c.onmicrosoft.com/41daebcb-3078-4014-b544-cb623a69ccb9',
    webApi: `https://${environment.urlBaseApiManagement}.azure-api.net/credito/v1.0.0/speakers`,
    tokenApiManagment: tokenApiManagement()
}

function tokenApiManagement() {
    if (environment.ambiente === "prd") {
        return ("https://api.interactuar.org.co/api/b2c/pdn/v1.0.0/Token")
    }
    return ('https://apimplataformadigitalbasic.azure-api.net/api/b2c/qa/v1.0.0/Token') //No modificar a QA
}

export const graph = graphEndPoints();

function graphEndPoints() {
    if (environment.ambiente === "prd") {
        return ({
            userAttributes: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/prd/v1.0.0/UserAttributes`,
            userStatus: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/prd/v1.0.0/UserStatus`,
            listUsers: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/prd/v1.0.0/Users`,
            sendNotification: `https://apimplataformadigitalbasic.azure-api.net/api/salesforce/qa/v1.0.0/services/apexrest/notification`,
        })
    }
    return ({
        userAttributes: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/dev/v1.0.0/UserAttributes`, //No modificar a QA
        userStatus: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/dev/v1.0.0/UserStatus`, //No modificar a QA
        listUsers: `https://${environment.urlBaseApiManagement}.azure-api.net/api/graph/dev/v1.0.0/Users`,
        sendNotification: `https://apimplataformadigitalbasic.azure-api.net/api/salesforce/qa/v1.0.0/services/apexrest/notification`,
    })
}

export const onCredit = {
    login: `https://${environment.urlBaseApiManagement}.azure-api.net/coxti/${environment.ambiente}/v1.0.0/users/login`,
    enableValidate: `https://${environment.urlBaseApiManagement}.azure-api.net/coxti/${environment.ambiente}/v1.0.0/enableValidate?token=`,
    identityValidation: environment.identityValidationCoxti,
    validationStatus: `https://${environment.urlBaseApiManagement}.azure-api.net/coxti/${environment.ambiente}/v1.0.0/validationStatus`,
    urlBaseCreditos: `https://${environment.urlBaseApiManagement}.azure-api.net/api/talend/${environment.ambiente}/v1.0.0/services/api/web/core/micrositio/generacion/certificados/`,
    urlDescargarPDFOracle: `https://${environment.urlBaseApiManagement}.azure-api.net/api/talend/pru/v1.0.0/services/api/oracle/certificado/`
}

export const initialSalesforce = {
    OAuth2: `https://apimplataformadigitalbasic.azure-api.net/salesforce/auth/qa/v1.0.0/services/oauth2/token`,
    parametrosGenerales: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/ParametrosGeneralesAutogestion`,
    creditosEstados: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/CreditosYEstadosAutogestion`,
    tipoCliente: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/TipoClienteAutogestion`,
    respuestaCliente: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/RespuestaDelClienteAutogestion`,
    estadoCarteraCliente: `https://${environment.urlBaseApiManagement}.azure-api.net/api/talend/${environment.ambiente}/v1.0.0/services/api/cobranza/consultaEstadoCarteraCliente/v1-0`,
    enviarNotificacion: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/EnviaNotificaciones`,
    urlDesudas: `https://apimplataformadigitalbasic.azure-api.net/api/talend/dev/v1.0.0/services/api/web/core/micrositio/generacion/certificados/consultacreditos/v1-0`,
    actualizarMovilCorreo: `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/ActualizaMovilCorreoCliente`,
    creditosVigentesSalesforce : `https://${environment.urlBaseApiManagement}.azure-api.net/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest`
}


export const oracle = {
    simulador: oracleEndPoint()
}

function oracleEndPoint() {
    if (environment.ambiente === "prd") {
        return "https://apimplataformadigitalpdn.azure-api.net/api/oracle/v1.0.0"
    }
    return `https://${environment.urlBaseApiManagement}.azure-api.net/oracle/${environment.ambiente}/v1.0.0/` //No modificar a QA
}

export const SIIF = {
  tokenSIIF: `https://${environment.urlBaseApiManagement}.azure-api.net/api/siif/auth/${environment.ambiente}/v3.0.0/WSJWTR_INT/Seguridad/Autenticacion`,
  estadoCarteraCredito: `https://${environment.urlBaseApiManagement}.azure-api.net/api/siif/${environment.ambiente}/v1.0.0/WSGYG62_INT/ConsultaEstadoCarteraCredito`,
  consultarCampana: `https://${environment.urlBaseApiManagement}.azure-api.net/api/siif/${environment.ambiente}/`,
  estadoCredito: `https://${environment.urlBaseApiManagement}.azure-api.net/api/siif/${environment.ambiente}/v1.0.0/WSGYG64_INT/GeneracionReporte`,
  deudaPazYSalvo:`https://${environment.urlBaseApiManagement}.azure-api.net/api/siif/${environment.ambiente}/v1.0.0/WSGYG71_INT/GenerarCertificado`

}

export const ORACLE = {
  pdfOracle: `https://${environment.urlBaseApiManagement}.azure-api.net/api/talend/${environment.ambiente}/v1.0.0/services/api/oracle/certificado/`
}


