import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import { HomeService } from '../home/home.service';
import { LoadingInfoService } from "../../reusableComponents/loading-info/loading-info.service";
import { MessagesService } from "../../reusableComponents/messages/messages.service";
import { AppService } from 'src/app/app.service';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AdminService } from '../admin/admin.service';
import { ReenviarCodigoComponent } from "../../components/reenviar-codigo/reenviar-codigo.component";
import * as CryptoJS from "crypto-js";
import { ModalActualizarPerfilComponent } from '../home/modal-actualizar-perfil/modal-actualizar-perfil.component';
import { ModalActualizarPerfilService } from '../.././components/home/modal-actualizar-perfil/modal-actualizar-perfil.service';


@Component({
  selector: 'app-validate-identity',
  templateUrl: './validate-identity.component.html',
  styleUrls: ['./validate-identity.component.css']
})

export class ValidateIdentityComponent implements OnInit {
  @ViewChild('input1') input1Element: ElementRef;
  @ViewChild('input2') input2Element: ElementRef;
  @ViewChild('input3') input3Element: ElementRef;
  @ViewChild('input4') input4Element: ElementRef;

  iput1 = ''
  iput2 = ''
  iput3 = ''
  iput4 = ''
  segundos = 1
  formNumeroDocumento: FormGroup;
  ingresoCodigo = false
  coidgoVencido = false
  textoBienvenida = 'Se ha enviado un código de verificación a su número de celular y/o correo electrónico'


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      OTP: string,
      intentos: string,
      idUser: string, appService
      IscoidgoVencido: string,
      origen: string
    },
    public Modal: MatDialog,
    private homeService: HomeService,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService,
    private appService: AppService,
    private fb: FormBuilder,
    private authService: MsalService,
    private adminService: AdminService,
    private ModalActualizarPerfilService: ModalActualizarPerfilService
  ) {


  }

  ngOnInit(): void {
    if (localStorage.getItem("origenValidaridentidad") == 'ActualizarDatos') {
      this.textoBienvenida = 'Se ha enviado un código de verificación a su número de celular'
    }

    if (this.data.IscoidgoVencido == "1") {
      this.coidgoVencido = true
    }
    setTimeout(() => {
      this.input1Element.nativeElement.focus();
    }, 1000);

    this.formNumeroDocumento = new FormGroup({
      code1: new FormControl("", [Validators.required, Validators.minLength(1), Validators.pattern('[0-9]')]),
      code2: new FormControl("", [Validators.required, Validators.minLength(1), Validators.pattern('[0-9]')]),
      code3: new FormControl("", [Validators.required, Validators.minLength(1), Validators.pattern('[0-9]')]),
      code4: new FormControl("", [Validators.required, Validators.minLength(1), Validators.pattern('[0-9]')]),

    });
    this.vigencia();
  }

  validateCode() {
    if (this.formNumeroDocumento.status == "VALID") {
      if (this.coidgoVencido || this.data.OTP == 'VENCIDO') {
        this.Modal.closeAll();
        this.vencioCodigo()
        return
      }

      this.Modal.closeAll()
      this.loadingService.startLoading();
      let condeSend = this.formNumeroDocumento.controls.code1.value + this.formNumeroDocumento.controls.code2.value + this.formNumeroDocumento.controls.code3.value + this.formNumeroDocumento.controls.code4.value
      if (this.data.OTP == condeSend) {

        if (localStorage.getItem("origenValidaridentidad") == 'ActualizarDatos') {
          localStorage.removeItem("origenValidaridentidad")
          this.updatePersonalData(localStorage.getItem("correoActualizar"), localStorage.getItem("celularActualizar"))
        } else {
          var id = this.data.idUser

          let getAccount = this.authService.getAccount();

          this.appService.getUserAttributes(id).toPromise().then(
            data => {
              this.adminService.updateAtrributes(id, localStorage.getItem('tokenB2C'), true, 'virtual', 2).toPromise().then(
                () => {
                  this.appService.blockUser(id, true).subscribe();
                  this.loadingService.stopLoading();

                  this.messageService.visibleMessage({
                    imgName: "success.svg",
                    texto1: "¡Bienvenido a Interactuar!",
                    texto2: "La validación de su identidad ha sido exitosa.",
                    button: "Continuar"
                  }, "CerrarSesion");
                },
                error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
              )
            },
            error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
          );
        }

      } else {
        if (this.data.intentos == "2") {

          this.appService.blockUser(this.data.idUser, false).subscribe(
            () => {

              this.messageService.visibleMessage({
                imgName: "error.svg",
                texto1: "",
                msnBloquaUsurio: true,
                button: "Cerrar",
                enlace: "https://interactuar.org.co/oficinas/",
              }, "bloqueo")

            },
            () => {
              this.appService.updateTokens().subscribe(
                () => {
                  var motivo = "Fallló validación de identidad en el registro"

                  if (localStorage.getItem("origenValidaridentidad")
                  == 'ActualizarDatos') {
                    motivo = "Fallló validación de identidad en el actualizar datos"
                  }

                  this.appService.blockUser(this.data.idUser, false, motivo).subscribe(
                    () => {
                      this.messageService.visibleMessage({
                        imgName: "error.svg",
                        texto1: "La validación de tus datos no fue exitosa.",
                        texto2: "Hemos bloqueado tu usuario para garantizar la seguridad de tus datos. Comunícate con nosotros para desbloquearlo, llamando al",
                        texto3: "01 8000 417 019 o ",
                        whatsapp: true,
                        button: "Cerrar"
                      }, "bloqueo")
                    },
                    error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
                  )
                },
                error => this.ErrorReintentarCerrarSesión(error)
              )
            }

          )
        } else {
          this.messageService.visibleMessage(
            {
              title: '',
              imgName: "error.svg",
              texto1: ``,
              msnintentosValidacion: true,
              button: "Cerrar",
              parametro: this.data.OTP
            },
            "ReenviarCodigo"
          )
        }
      }

    } else {
      this.ingresoCodigo = true
    }
  }

  vencioCodigo() {
    this.messageService.visibleMessage(
      {
        title: '',
        imgName: "error.svg",
        texto1: ``,
        msnvencidoValidacion: true,
        button: "Cerrar",
        parametro: "1",
        texto3: "VENCIDO"
      },
      "infoOTP2"
    )

  }

  vigencia() {
    setTimeout(() => {
      this.vigencia();
    }, 1000);

    this.segundos++
    if (this.segundos == (parseInt(environment.minutosCodigoOTP.toString()) * 60)) {
      this.coidgoVencido = true
    }
  }

  enviarCodigo(OTP: string, celular: string, correo: string, documento: string) {
    this.appService.getUserAttributes(this.data.idUser).toPromise().then(
      data => {
        this.appService.validateIdentity(OTP, data.userSignIn).toPromise().then(
          data => {
            if (data.notificaciones.sms.estado == 'OK' || data.notificaciones.email.estado == 'OK') {
              this.messageService.visibleMessage(
                {
                  imgName: "error.svg",
                  texto1: `Error `,
                  texto2: "Tu solicitud no pudo ser completada. Por favor intente nuevamente.",
                  button: "Reintentar",
                  button2: "Cerrar sesión"
                },
                "info", "Close2"
              )
            } else {
              this.messageService.visibleMessage(
                {
                  imgName: "error.svg",
                  texto1: ``,
                  button: "Cerrar",
                  msnNoficacionFallida: true
                },
                "CerrarSesion"
              )
            }


          },
          error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })

        )
      })
  }

  ErrorReintentarCerrarSesión(error) {
    this.messageService.visibleMessage(
      {
        imgName: "error.svg",
        texto1: `Error ${error.origin}`,
        texto2: "Tu solicitud no pudo ser completada. Por favor intente nuevamente.",
        button: "Reintentar",
        button2: "Cerrar sesión"
      },
      "redirect", "Close2"
    )
  }

  updateInput(event) {
    let next = event.target.nextElementSibling;
  }

  validateIdentity() {
    this.Modal.closeAll()
    const dialogRef = this.Modal.open(ReenviarCodigoComponent, {
      data: { OTP: this.data.OTP, intetos: "1" },
      maxWidth: '70vh',
      maxHeight: '95vh',
      panelClass: 'openpazysalvoContainer',
      disableClose: true
    });
  }
  getRandomInt(max, min) {
    return Math.floor((Math.random() * (max - min + 1)) + min);
  }

  onKeypressEvent(e: any) {
    this.input2Element.nativeElement.focus();
  }
  onKeypressEvent2(e: any) {
    this.input3Element.nativeElement.focus();
  }
  onKeypressEvent3(e: any) {
    this.input4Element.nativeElement.focus();
  }

  cerrar() {
    this.Modal.closeAll()
    if (localStorage.getItem("origenValidaridentidad")
      == 'ActualizarDatos') {
      localStorage.removeItem("origenValidaridentidad")
      this.actualizarPerfil()
    } else {
      this.loadingService.startLoading();
      localStorage.removeItem('tokenSalesforce');
      localStorage.removeItem('tokenSB2C');
      localStorage.removeItem('user');
      localStorage.removeItem('object');
      localStorage.removeItem("tasaPreAprobado");
      localStorage.removeItem("origenValidaridentidad")

      this.appService.logout();
    }

  }

  actualizarPerfil() {
    let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.Modal.open(ModalActualizarPerfilComponent, {
      data: {
        correoElectronico: user.Correo,
        numeroCelular: user.NumeroCelular,
        type: 0
      },
      maxWidth: '100vw',
      maxHeight: '95vh',
      panelClass: 'actualizarPerfilMod'
    });
  }

  updateEmailAttribute(correo, celular) {
    this.ModalActualizarPerfilService.updateEmailAttribute(correo, celular).subscribe(
      () => {
        let user = {
          Correo: correo,
          NumeroCelular: celular
        };
        localStorage.setItem("userMail", CryptoJS.AES.encrypt(JSON.stringify(user), environment.constantKey).toString());
        // this.dialogRef.close();
        this.messageService.visibleMessage({
          imgName: "success.svg",
          texto1: "Datos actualizados exitosamente",
          button: "Cerrar"
        }, "Info");
      },
      error => {
        // this.dialogRef.close();
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
      }
    )
  }

  sendUpdateNotification(correo, celular) {
    const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
    this.appService.sendNotification(extension_TipodeDocumento, correo, celular, 'Update').subscribe(data => {
    });
  }

  updatePersonalData(correo, celular) {
    const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
    this.ModalActualizarPerfilService.updatePersonalData(extension_TipodeDocumento, correo, celular).subscribe(
      data => {
        console.log(data);

        if (data.respuesta.respuestaCelular == "Número celular actualizado.") {
          this.sendUpdateNotification(correo, celular);
        }
        if (data.respuesta.estado == "Error técnico") {
          this.messageService.errorMessage({ title: `Error técnico`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
        }else{
          this.updateEmailAttribute(correo, celular);
        }
      },
      error => {
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
      }
    )
  }

}
