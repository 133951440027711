import { createReducer, on } from '@ngrx/store';
import { SolicitarCreditoSinPreaprobado } from 'src/app/constants/interfaces/solicitar-credito.interface';
import { saveFormActualizarDatos, saveFormDesembolso, saveFormSeguro, saveCreditoPreaprobadoSinPreaprobado } from './solicitar-credito-sin-preabrobado.actions';
 
export const initialState: SolicitarCreditoSinPreaprobado = {
    formActualizarDatos: {},
    formDesembolso: {},
    formSeguro: {},
    preaprobado: {}
};
 
const _solicitarCreditoReducer = createReducer(
  initialState,
  on(saveFormActualizarDatos, (state, { form }) => {
    return {
        ...state,
        formActualizarDatos: form
    };
    }),
  on(saveFormDesembolso, (state, { form }) => {
    return {
        ...state,
        formDesembolso: form
    };
    }),
  on(saveFormSeguro, (state, { form }) => {
    return {
        ...state,
        formSeguro: form
    };
    }),
  on(saveCreditoPreaprobadoSinPreaprobado, (state, { data }) => {
    return {
        ...state,
        preaprobado: data
        };
    })
);

export function solicitarCreditoSinPreaprobadoReducer(state, action) {
  return _solicitarCreditoReducer(state, action);
}