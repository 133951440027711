import { Injectable } from '@angular/core';

import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';

import { AppService } from '../../app.service';
import { LoadingInfoService } from '../loading-info/loading-info.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidateIdentityComponent } from 'src/app/components/validate-identity/validate-identity.component';
import { Router } from '@angular/router';
import { ReenviarCodigoComponent } from 'src/app/components/reenviar-codigo/reenviar-codigo.component';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  _messageVisible: boolean = false;
  pathImages: string = '../../../assets/Images/';
  messageStatus: Subject<boolean> = new Subject();
  tipoModal: string = 'Info';
  tipoModal2: string = null;
  message: {
    img: string;
    title?: string;
    texto1: string;
    texto2?: string;
    texto3?: string;
    enlace?: string;
    button: string;
    button2?: string;
    whatsapp?: boolean;
    msnJuridicial?: boolean;
    msncreditos?: boolean;
    msnSimulador?: boolean;
    msnCreditosSelect?: boolean;
    msnCreditosJuridico?: boolean;
    parametro?: string;
    msnCreditosinregistro?: boolean;
    msnintentosValidacion?: boolean;
    msnvencidoValidacion?: boolean;
    msnNoficacionFallida?: boolean;
    msnBloquaUsurio?: boolean;
    msnCreditoCancelado?: boolean;
    msnServiceError?: boolean;
  } = {
    img: `${this.pathImages}warning.svg`,
    title: null,
    texto1: 'Presione continuar.',
    texto2: null,
    texto3: null,
    enlace: null,
    button: 'Continuar',
    button2: null,
    whatsapp: false,
    msnJuridicial: false,
    msnServiceError: false,
    msncreditos: false,
    msnSimulador: false,
    msnCreditosSelect: false,
    msnCreditosJuridico: false,
    parametro: null,
    msnCreditosinregistro: false,
    msnintentosValidacion: false,
    msnvencidoValidacion: false,
    msnNoficacionFallida: false,
    msnBloquaUsurio: false,
    msnCreditoCancelado: false,
  };
  datos = {};
  intentos = 0;
  OTP = 0;
  idUser = '';

  constructor(
    private appService: AppService,
    private loadingService: LoadingInfoService,
    private authService: MsalService,
    public Modal: MatDialog,
    private router: Router
  ) {}

  get messageVisible(): boolean {
    return this._messageVisible;
  }

  set messageVisible(value) {
    this._messageVisible = value;
    this.messageStatus.next(value);
  }

  visibleMessage(
    cardParams: {
      imgName: string;
      title?: string;
      texto1: string;
      texto2?: string;
      texto3?: string;
      enlace?: string;
      button: string;
      button2?: string;
      whatsapp?: boolean;
      msnJuridicial?: boolean;
      msncreditos?: boolean;
      msnSimulador?: boolean;
      msnCreditosSelect?: boolean;
      msnCreditosJuridico?: boolean;
      parametro?: string;
      msnCreditosinregistro?: boolean;
      msnintentosValidacion?: boolean;
      msnvencidoValidacion?: boolean;
      msnNoficacionFallida?: boolean;
      msnBloquaUsurio?: boolean;
      msnCreditoCancelado?: boolean;
      msnServiceError?: boolean;
    },
    tipoModal: string,
    tipoModal2?: string
  ): void {
    this.loadingService.stopLoading();
    this.message = { img: this.pathImages + cardParams.imgName, ...cardParams };
    this.tipoModal = tipoModal;
    if (tipoModal2) {
      this.tipoModal2 = tipoModal2;
    }
    this._messageVisible = true;
  }

  errorMessage(cardParams: { title: string; texto1: string }): void {
    this.loadingService.stopLoading();
    this.message = {
      img: `${this.pathImages}error.svg`,
      texto3: null,
      button: 'Aceptar',
      enlace: null,
      ...cardParams,
    };
    this.tipoModal = 'Info';
    this._messageVisible = true;
  }

  invisibleMessage(): void {
    this._messageVisible = false;
    //si cumple estas condiciones manda a llamar el modal de cerrar sesion
    switch (this.tipoModal) {
      case 'Info':
        break;
      case 'OnCredit':
        this.loadingService.startLoading();
        //Se cambia lo que se envia para redirección OTP a true
        this.appService.enableForm('true').subscribe(null, (error) => {
          this.errorMessage({
            title: `En este momento no se encuentra disponible el servicio de validación.`,
            texto1: 'intente mas tarde, gracias.',
          });
        });
        break;
      case 'OnCredit2':
        this.loadingService.startLoading();
        //Se cambia lo que se envia para redirección VQ a false
        this.appService.enableForm('false').subscribe(null, (error) => {
          this.errorMessage({
            title: `En este momento no se encuentra disponible el servicio de validación.`,
            texto1: 'intente mas tarde, gracias.',
          });
        });
        break;
      case 'Loading':
        this.loadingService.startLoading();
        break;
      case 'LoadingStop':
        this.loadingService.stopLoading();
        break;
      case 'bloqueo':
        localStorage.removeItem('tokenSalesforce');
        localStorage.removeItem('tokenSB2C');
        localStorage.removeItem('user');
        localStorage.removeItem('object');
        localStorage.removeItem('tasaPreAprobado');
        this.loadingService.startLoading();
        this.appService.logout();
        break;
      case 'recargarPagina':
        location.reload();
        break;
      case 'Contacto':
        window
          .open('https://interactuar.org.co/quiero-credito/"', '_blank')
          .focus();
        break;
      case 'CerrarSesion':
        localStorage.removeItem('tokenSalesforce');
        localStorage.removeItem('tokenSB2C');
        localStorage.removeItem('user');
        localStorage.removeItem('object');
        localStorage.removeItem('tasaPreAprobado');
        this.loadingService.startLoading();
        this.appService.logout();
        break;
      case 'ElimiarCuenta':
        this.loadingService.startLoading();
        let {
          idTokenClaims: { sub },
        } = this.authService.getAccount();
        this.appService.blockUser(sub, false).subscribe(
          () =>
            this.appService.deleteUser(sub).subscribe(
              () => {
                localStorage.removeItem('tokenSalesforce');
                localStorage.removeItem('tokenSB2C');
                localStorage.removeItem('user');
                localStorage.removeItem('object');
                this.appService.logout();
              },
              (error) => this.errorRequest(error)
            ),
          () => {
            this.appService.updateTokens().subscribe(
              () =>
                this.appService.blockUser(sub, false).subscribe(
                  () =>
                    this.appService.deleteUser(sub).subscribe(
                      () => {
                        localStorage.removeItem('tokenSalesforce');
                        localStorage.removeItem('tokenSB2C');
                        localStorage.removeItem('user');
                        localStorage.removeItem('object');
                        this.appService.logout();
                      },
                      (error) => this.errorRequest(error)
                    ),
                  (error) => this.errorRequest(error)
                ),
              (error) => this.errorRequest(error)
            );
          }
        );
        break;
      case 'validate':
        this.validar();
        break;
      case 'redirect':
        this.router.navigate(['/']);
        break;
      case 'infoOTP':
        this.validarOTP(this.OTP, this.idUser);
        break;
      case 'infoOTP2':
        this.validarOTP(
          this.message.texto3,
          this.idUser,
          this.message.parametro
        );
        break;
      case 'ReenviarCodigo':
        this.reenviarCodigo();
        break;
      default:
        break;
    }
  }

  getRandomInt(max, min) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  errorRequest(error) {
    this.visibleMessage(
      {
        imgName: 'error.svg',
        texto1: `Error ${error.origin}`,
        texto2:
          'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
        button: 'Reintentar',
        button2: 'Cerrar sesión',
      },
      'recargarPagina',
      'Close2'
    );
  }

  invisibleMessageClose(): void {
    this._messageVisible = false;
    //si cumple estas condiciones manda a llamar el modal de cerrar sesion
    if (this.tipoModal == 'OnCredit' && this.tipoModal2) {
      switch (this.tipoModal2) {
        case 'Close':
          this.invisibleMessage3();
          break;
        case 'Close2':
          localStorage.removeItem('tokenSalesforce');
          localStorage.removeItem('tokenSB2C');
          localStorage.removeItem('user');
          localStorage.removeItem('object');
          localStorage.removeItem('tasaPreAprobado');
          this.loadingService.startLoading();
          this.appService.logout();
          break;
      }
    } else this.invisibleMessage();
  }

  invisibleMessage2(): void {
    this._messageVisible = false;
    switch (this.tipoModal2) {
      case 'Info':
        break;
      case 'Close2':
        localStorage.removeItem('tokenSalesforce');
        localStorage.removeItem('tokenSB2C');
        localStorage.removeItem('user');
        localStorage.removeItem('object');
        this.loadingService.startLoading();
        this.appService.logout();
        break;
      case 'recargarPagina':
        location.reload();
        break;
      default:
        break;
    }
  }

  //crea un nuevo modal para cierre de sesion
  invisibleMessage3(): void {
    this._messageVisible = true;
    switch (this.tipoModal) {
      case 'OnCredit':
        this.visibleMessage(
          {
            imgName: 'warning.svg',
            texto1:
              'Si no realiza la validación de su identidad, no se le mostrara información',
            button: 'Validar identidad',
            button2: 'Cerrar sesión',
          },
          'OnCredit',
          'Close2'
        );
        break;
      default:
        break;
    }
  }

  cerrar() {
    this._messageVisible = false;
  }

  validar() {
    this.loadingService.startLoading();
    //Se cambia lo que se envia para redirección OTP a true
    this.OTP = this.getRandomInt(9999, 1000);
    this.idUser = this.message.texto3;
    this.appService
      .getUserAttributes(localStorage.getItem('idUsuario'))
      .toPromise()
      .then((data) => {
        this.appService
          .validateIdentity(this.OTP.toString(), data.userSignIn)
          .toPromise()
          .then(
            (data) => {
              if (
                data.notificaciones.sms.estado == 'OK' ||
                data.notificaciones.email.estado == 'OK'
              ) {
                var texto1 = `Código enviado a su celular y/o correo electrónico`;
                if (
                  localStorage.getItem('origenValidaridentidad') ==
                  'ActualizarDatos'
                ) {
                  texto1 = 'Código enviado a su celular';
                }
                this.visibleMessage(
                  {
                    imgName: 'info.svg',
                    texto1: texto1,
                    button: 'Cerrar',
                  },
                  'infoOTP'
                );
              } else {
                this.visibleMessage(
                  {
                    imgName: 'error.svg',
                    texto1: ``,
                    button: 'Cerrar',
                    msnNoficacionFallida: true,
                  },
                  'CerrarSesion'
                );
              }
            },
            (error) =>
              this.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
              })
          );
      });
  }

  validarOTP(OTP, idUser, intentos = '1') {
    this.datos = {
      OTP: OTP,
      intentos: intentos.toString(),
      idUser: idUser,
      IscoidgoVencido: this.message.parametro,
      origen: this.message.parametro,
    };
    this.loadingService.stopLoading();
    const dialogRef = this.Modal.open(ValidateIdentityComponent, {
      data: this.datos,
      maxWidth: '70vh',
      maxHeight: '95vh',
      panelClass: 'openpazysalvoContainer',
      disableClose: true,
    });
  }

  reenviarCodigo() {
    this.Modal.closeAll();
    const dialogRef = this.Modal.open(ValidateIdentityComponent, {
      data: {
        OTP: this.message.parametro,
        intentos: '2',
        idUser: localStorage.getItem('idUsuario'),
      },
      maxWidth: '70vh',
      maxHeight: '95vh',
      panelClass: 'openpazysalvoContainer',
      disableClose: true,
    });
  }
}
