// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//para pruebas de QA en dev reemplazar por la constante de environment.prod
export const environment = {
  ambiente: "qa",
  production: false,
  constantKey: "Le!koA3vwX2ker@4!jfI6GoMsNaEPBglA4!!sZBW",
  emailCoxti: "integracion@coxti.net",
  passCoxti: "integracionC0xT1=",
  clientIdOnCredit: "3MVG9sLbBxQYwWqtI3hcR1Odn1x1zH4TrljkhAKNoyVtW.fgzFJQ7VeBAeAMZmWIVlETjOMsBBF0M8Y3XPnIR",
  clientSecretOnCredit: "57E148D9D5538EF5664301EC1AA86BE37CCC86AC991D6A7A324B6FA881EB1E3C",
  identityValidationCoxti: 'https://integracion.coxti.com/interactuar/identityValidation?token=',
  userNameOncredit: "integraciones@interactuar.org.co.dev",
  passwordOncredit: "1nt3r890",
  secretTokenB2C: "lKq8Q~Q2gvJn9~iZHMPJ-f9GaRsbbe.d7evIKbpg",
  clientIdTokenB2C: "ba0e1549-09f4-4463-a1c8-b94e1379e13b",
  SubscriptionKey: "8aa998f3977744229b76869aa4854e0f",
  urlBase: "https://interactuar-pruebas.interactuar.net/",
  urlbaseB2C: 'interactuarapolob2c',
  urlBaseApiManagement: 'apimplataformadigitalbasic',
  clientIdB2c: "721a1e4d-14c7-43f4-a4a1-62f7869f5914",
  anoGrabableInicio: 2017,
  idCampanaSinPreaprobados: "701050000002ydEAAQ",
  mesesCacelacion: 12,
  minutosCodigoOTP:4,
  Semillainicialización: "5183666c26eec7q4",
  Semillasecreto: "bf3c139c2470cb627d907b1e0917c35b",
  UrlJsonServer : "https://micrositioback-interactuar.azurewebsites.net"

};

/* export const environment = {
  ambiente: "pdn",
  production: false,
  constantKey: "Le!koA3vwX2ker@4!jfI6GoMsNaEPBglA4!!sZBW",
  emailCoxti: "sistematizado@interactuar.org.co",
  passCoxti: "interactuar()=?**;",
  identityValidationCoxti: 'https://interactuar.coxti.com/identityValidation?token=',
  clientIdOnCredit: "3MVG9A2kN3Bn17hto3il3.Q7Fa0Fe3Gyyf9Wp1R3xVVpbRSuxgbHnH0EmDRStgiOT_wjeBSocCUBEwSGhYX2z",
  clientSecretOnCredit: "14E26875ADE0CC57B718189A7370632D4055565789198B414135F84DC0D65C73",
  userNameOncredit: "integraciones2@interactuar.org.co ",
  passwordOncredit: "1nt3r890",
  secretTokenB2C: "tg0KQW~DcbT5T55DMcFlq.EOP~44KVMGL.",
  clientIdTokenB2C: "",
  SubscriptionKey: "f71f71c4caa34e34833dfc83b9e63173",
  urlBase: 'http://localhost:4200/',
  urlbaseB2C: 'interactuarb2c',
  urlBaseApiManagement: 'apimplataformadigitalpdn',
  clientIdB2c: "41daebcb-3078-4014-b544-cb623a69ccb9"
}; */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
