import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { LoadingInfoService } from 'src/app/reusableComponents/loading-info/loading-info.service';
import { MessagesService } from 'src/app/reusableComponents/messages/messages.service';
import { ValidateIdentityComponent } from "../../components/validate-identity/validate-identity.component";

@Component({
  selector: 'app-reenviar-codigo',
  templateUrl: './reenviar-codigo.component.html',
  styleUrls: ['./reenviar-codigo.component.css']
})
export class ReenviarCodigoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      OTP: string,
      intetos: string
    },
    private loadingService: LoadingInfoService,
    public Modal: MatDialog,
    private messageService: MessagesService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
  }

  cerrar() {
    this.Modal.closeAll()
    var datos = { OTP: this.data.OTP, intentos: this.data.intetos, idUser: localStorage.getItem("idUsuario"), IscoidgoVencido: '0' }
    this.loadingService.stopLoading();
    const dialogRef = this.Modal.open(ValidateIdentityComponent, {
      data: datos,
      maxWidth: '70vh',
      maxHeight: '95vh',
      panelClass: 'openpazysalvoContainer',
      disableClose: true
    });
  }

  reenviar() {
    this.Modal.closeAll()
    this.loadingService.startLoading();

    const OTP = this.getRandomInt(9999, 1000)

    this.appService.getUserAttributes(localStorage.getItem("idUsuario")).toPromise().then(
      data => {

        this.appService.validateIdentity(OTP.toString(), data.userSignIn).toPromise().then(
          data => {
            if (data.notificaciones.sms.estado == 'OK' || data.notificaciones.email.estado == 'OK') {
              var datos = { OTP: OTP, intentos: this.data.intetos, idUser: localStorage.getItem("idUsuario"), IscoidgoVencido: '0' }
              this.loadingService.stopLoading();
              const dialogRef = this.Modal.open(ValidateIdentityComponent, {
                data: datos,
                maxWidth: '70vh',
                maxHeight: '95vh',
                panelClass: 'openpazysalvoContainer',
                disableClose: true
              });
            } else {
              this.loadingService.stopLoading();
              this.messageService.visibleMessage(
                {
                  imgName: "error.svg",
                  texto1: ``,
                  button: "Cerrar",
                  msnNoficacionFallida: true
                },
                "CerrarSesion"
              )
            }
          },
          error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
        )
      })


  }

  getRandomInt(max, min) {
    return Math.floor((Math.random() * (max - min + 1)) + min);
  }

}
