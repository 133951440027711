import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import * as CryptoJS from "crypto-js";
import { Subscription } from 'rxjs';

import { MessagesService } from '../../../reusableComponents/messages/messages.service';
import { ModalActualizarPerfilService } from "./modal-actualizar-perfil.service";
import { environment } from "../../../../environments/environment";
import { AppService } from 'src/app/app.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-modal-actualizar-perfil',
  templateUrl: './modal-actualizar-perfil.component.html',
  styleUrls: ['./modal-actualizar-perfil.component.css']
})
export class ModalActualizarPerfilComponent implements OnInit {
  formActualizarDatos: FormGroup;
  loadingService: boolean = false;
  messageVisble: boolean = false; //Bandera para la visualización de mensajes
  messageSubscription: Subscription;
  getAccount:any

  constructor(
    private authService: MsalService,
    private appService: AppService,
    public dialogRef: MatDialogRef<ModalActualizarPerfilComponent>,
    private messageService: MessagesService,
    private ModalActualizarPerfilService: ModalActualizarPerfilService,
    @Inject(MAT_DIALOG_DATA) public data: {
      correoElectronico: string,
      numeroCelular: string,
      type: string
    }
  ) {
    this.formActualizarDatos = new FormGroup({
      correoElectronico: new FormControl(this.data.correoElectronico, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]),
      numeroCelular: new FormControl(this.data.numeroCelular, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)])
    })
  }

  ngOnInit(): void {
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
  }

  onSubmit() {
    /* if (this.formActualizarDatos.valid) {
      this.dialogRef.disableClose = true;
      this.loadingService = true;
      if (parseInt(this.data.type) != 1) {
        this.updateEmailAttribute();
      }
      else { */
      this.dialogRef.removePanelClass('mat-dialog-container');
        this.dialogRef.close();
        this.ModalActualizarPerfilService.savePersonalData(this.formActualizarDatos.value.correoElectronico, this.formActualizarDatos.value.numeroCelular).subscribe();



        //Validar identidad
        this.getAccount = this.authService.getAccount();
        localStorage.setItem("correoActualizar", this.formActualizarDatos.value.correoElectronico)
        localStorage.setItem("celularActualizar", this.formActualizarDatos.value.numeroCelular)
        this.validateIdentity(this.getAccount.idTokenClaims.sub)
      //}
    //}
  }

  sendUpdateNotification() {
    const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
    let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.appService.sendNotification(extension_TipodeDocumento, user.Correo, user.NumeroCelular, 'Update').subscribe(data => {
    });
  }

  updatePersonalData() {
    const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
    this.ModalActualizarPerfilService.updatePersonalData(extension_TipodeDocumento, this.formActualizarDatos.value.correoElectronico, this.formActualizarDatos.value.numeroCelular).subscribe(
      data => {
        if (data.respuesta.respuestaCelular == "Número celular actualizado.") {
          this.sendUpdateNotification();
        }
        if (data.respuesta.estado == "Error técnico") {
          this.messageService.errorMessage({ title: `Error técnico`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
        }else{
          this.updateEmailAttribute();
        }
      },
      error => {
        this.dialogRef.close();
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
      }
    )
  }

  updateEmailAttribute() {
    this.ModalActualizarPerfilService.updateEmailAttribute(this.formActualizarDatos.value.correoElectronico, this.formActualizarDatos.value.numeroCelular).subscribe(
      () => {
        let user = {
          Correo: this.formActualizarDatos.value.correoElectronico,
          NumeroCelular: this.formActualizarDatos.value.numeroCelular
        };
        localStorage.setItem("userMail", CryptoJS.AES.encrypt(JSON.stringify(user), environment.constantKey).toString());
        this.dialogRef.close();
        this.messageService.visibleMessage({
          imgName: "success.svg",
          texto1: "Datos actualizados exitosamente",
          button: "Cerrar"
        }, "Info");
      },
      error => {
        this.dialogRef.close();
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: "Tu solicitud no pudo ser completada. Por favor intente nuevamente." });
      }
    )
  }

  validateIdentity(id){
    this.appService.blockUser(id, true).subscribe();
    localStorage.setItem("idUsuario", id)
    localStorage.setItem("origenValidaridentidad", 'ActualizarDatos')
    this.messageService.visibleMessage({
      imgName: "info.svg",
      texto1: "Por tu seguridad debemos validar tu identidad.",
       texto2: "Para continuar, se te enviará un código de verificación a tu número celular",
       texto3: id,
      button: "Aceptar"
    }, "validate", "Close");

  }

  get ActualizarDatosForm() { return this.formActualizarDatos }
}
