import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormArray } from "@angular/forms";

import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as CryptoJS from "crypto-js";

import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { environment } from "../../../../environments/environment";
import { saveFormSeguro } from '../state/solicitar-credito-sin-preabrobado.actions';
import { StateCreditos } from 'src/app/constants/interfaces/solicitar-credito.interface';

@Component({
  selector: 'app-datos-del-seguro',
  templateUrl: './datos-del-seguro.component.html',
  styleUrls: ['./datos-del-seguro.component.css']
})
export class DatosDelSeguroComponent implements OnInit {
  messageBack: boolean = false;
  changePercentage: boolean = false;
  errorSubmit: boolean = false;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  initialPercentage: string[] = [];
  creditoPreaprobado: { codigoProducto: number, estado: string, fechaDesembolso: string | null, monto: number, numeroCredito: number | null, plazo: number, simulador: number | null, idMiembroCampania: string | null, actividadEconomica: string, famiempresa: string };
  creditoPreaprobado$: Observable<any> = this.store.select(state => state.solicitarCreditoSinPreaprobado.preaprobado);
  tipoDocumento: [];
  parentesco: [];
  formSolicitarCreditos2: FormArray = new FormArray([
    new FormGroup({
      nombreCompleto: new FormControl("", [Validators.required, Validators.pattern(/^[A-zÀ-ú\s]*$/)]), //Sin tildes ni ñ a-zA-Z\s
      tipoDocumento: new FormControl(null, [Validators.required]),
      numeroDocumento: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
      parentesco: new FormControl(null, [Validators.required]),
      cualParentesco: new FormControl(""),
      porcentaje: new FormControl("", [Validators.required, Validators.pattern('^[0-9.]*$')])
    })
  ]);

  constructor(private messageService: MessagesService, private loadingService: LoadingInfoService, private store: Store<StateCreditos>, private router: Router) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
    const valores = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8))
    this.tipoDocumento = valores[1].valores;
    this.parentesco = valores[3].valores;
    this.creditoPreaprobado$.pipe(take(1)).subscribe((data: any) => this.creditoPreaprobado = data);
    if(Object.keys(this.creditoPreaprobado).length === 0) this.router.navigate(["/"]);
  }

  ngOnInit(): void {
  }

  addBeneficiario() {
    if (this.formSolicitarCreditos2.value.length < 3) {
      const group = new FormGroup({
        nombreCompleto: new FormControl("", [Validators.required]),
        tipoDocumento: new FormControl(null, [Validators.required]),
        numeroDocumento: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
        parentesco: new FormControl(null, [Validators.required]),
        cualParentesco: new FormControl(""),
        porcentaje: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')])
      });
      this.formSolicitarCreditos2.push(group);
    }
    else {
      this.messageService.visibleMessage({
        imgName: "error.svg",
        texto1: "Sólo se permiten máximo 3 benefeciarios.",
        button: "Aceptar",
      }, "Error");
    }
  }

  deleteBeneficiario(index: number) {
    this.formSolicitarCreditos2.removeAt(index)
  }

  onSubmit() {
    this.changePercentage = false;
    if (this.formSolicitarCreditos2.valid) {
      this.errorSubmit = false;
      //VALIDAR PORCENTAJES
      let sumaPorcentaje: number = 0;
      for (const form of this.formSolicitarCreditos2.value) {
        this.initialPercentage.push(form.porcentaje)
        sumaPorcentaje += parseInt(form.porcentaje);
      }
      if (sumaPorcentaje === 100) {
        //Ciclo para evitar que se envíe un valor de cualParentesco, sabiendo que no se seleccionó otro como valor de parentesco
        for (let index = 0; index < this.formSolicitarCreditos2.value.length; index++) {
          if (this.formSolicitarCreditos2.value[index].parentesco !== "Otro") {
            this.formSolicitarCreditos2.value[index].cualParentesco = "";
          }
        }
        this.store.dispatch(saveFormSeguro({form: this.formSolicitarCreditos2.value }));
        this.router.navigate([`/cambiar-monto-4`]);
      }
      else {
        this.changePercentage = true;
      }
    }
    else {
      this.errorSubmit = true;
    }
  }

  regresar() {
    this.messageBack = true;
  }

  continuarSolicitud() {
    this.messageBack = false;
  }

  confirmarRegresar() {
    this.router.navigate([`/cambiar-monto-2`]);
  }

  showInfo() {
    this.messageService.visibleMessage({
      imgName: "info.svg",
      texto1: "La suma de los porcentajes asignados a los beneficiarios deben sumar el 100%.",
      button: "Aceptar",
    }, "Info");
  }

  //Función que se ejecuta cuando se selecciona un parentesco, y que vuelve obligarorio el campo cual parentesco en caso de seleccionar otro
  onChange(index: number) {
    if (this.formSolicitarCreditos2.value[index].parentesco === "Otro") {
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').setValidators([Validators.required])
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').updateValueAndValidity();
    }
    else {
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').setValidators(null)
      this.formSolicitarCreditos2.controls[index].get('cualParentesco').updateValueAndValidity();
      this.formSolicitarCreditos2.value[index].cualParentesco = "";
    }
  }

  totalPercentage() {
    let total: number = 0;
    for (let Beneficiario of this.formSolicitarCreditos2.value) {
      if (Beneficiario.porcentaje === "") {
        total += 0;
      }
      else {
        total += parseInt(Beneficiario.porcentaje);
      }
    }
    return total;
  }

  closeChangePercentage() {
    //Retornando a los valores iniciales para que no se quede con los valores modificados en la modal
    for (let index = 0; index < this.initialPercentage.length; index++) {
      this.formSolicitarCreditos2.value[index].porcentaje = this.initialPercentage[index]
    }
    this.initialPercentage = [];
    this.changePercentage = false;
  }

  get beneficiarios(): FormArray {
    return this.formSolicitarCreditos2 as FormArray;
  }

}
